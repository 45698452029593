import {Text} from '@rneui/themed';
import React, {useContext, useEffect} from 'react';
import {View} from 'react-native';
import {useRecoilState, useRecoilValue} from 'recoil';

import Paper from '../common/paper';
import {SortType} from '../common/store';
import PdfViewer from '../components/PdfViewer';
import {Toolbar, ToolbarItem} from '../components/ToolbarItem';
import {AppContext, NavigationContext} from '../context';
import {analytics} from '../platform/firebase';
import {
  IconConfig, IconHighlight, IconText, IconZoomIn, IconZoomOut,
} from '../platform/icons';
import {settingsState} from '../recoil/atoms';
import {currentPaperState} from '../recoil/atoms/papers';
import {layoutState} from '../recoil/selectors';
import {HTMLPaperViewer} from './HTMLPaperView';

type PaperViewPropsType = {
  paper: Paper;
};

export const PaperViewComponent = ({paper}: PaperViewPropsType) => {
  const {
    savePaperAndSync,
    onPaperOrderChanged,
    isPaperInLibrary,
  } = useContext(AppContext);
  const {navigate} = useContext(NavigationContext);
  const [settings, setSettings] = useRecoilState(settingsState);
  const layout = useRecoilValue(layoutState);

  useEffect(() => {
    // update dateOpened
    analytics?.logEvent('read_paper_pdf', {
      paperId: paper.id,
      paperTitle: paper.title,
    });

    if (isPaperInLibrary(paper.id) &&
      (paper.dateOpened || 0) < Date.now() - 5000) {
      savePaperAndSync({
        ...paper,
        dateOpened: Date.now(),
      });
      return () => {
        onPaperOrderChanged(SortType.ByDateOpened);
      };
    }
  }, [paper.id]);

  return (
    <View testID="pdf_viewer_container" style={{flex: 1}}>
      {settings.showPaperViewToolbar && <Toolbar borderBottom={true}>
        <ToolbarItem
          active={layout !== 'one-column' && settings.showNotes}
          marginRight={true}
          icon={IconHighlight} onPress={() => {
            if (layout === 'one-column') {
              navigate('Notes');
            } else {
              setSettings({...settings, showNotes: !settings.showNotes});
            }
          }} />
        <ToolbarItem marginRight={true} icon={IconZoomIn} onPress={() => {
          savePaperAndSync({...paper, zoom: paper.zoom + 10}, false);
        }} />
        <ToolbarItem marginRight={true} icon={IconZoomOut} onPress={() => {
          savePaperAndSync({...paper, zoom: paper.zoom - 10}, false);
        }} />
        <View style={{flexGrow: 1}}></View>
        <ToolbarItem
          active={paper.viewer === 'html'}
          marginRight={true}
          icon={IconText}
          onPress={() => {
            savePaperAndSync({
              ...paper,
              viewer: paper.viewer === 'html' ? 'pdf' : 'html',
            }, false);
          }}
        />
        <ToolbarItem icon={IconConfig} onPress={() => {
          navigate('Preferences', {screen: 'PdfViewer'});
        }} />
      </Toolbar>}
      {paper && (
        paper.viewer === 'pdf' ? (
          <PdfViewer paper={paper} />
        ) : (paper.viewer === 'html' ? <HTMLPaperViewer paper={paper} /> : (
          <Text style={{padding: 16, textAlign: 'center'}}>
            Could not retrieve the paper. Please try again later.
          </Text>
        ))
      )}
    </View>
  );
};

export const PaperViewScreen = (): JSX.Element => {
  const currentPaper = useRecoilValue(currentPaperState);
  return (
      currentPaper ? <PaperViewComponent paper={currentPaper} /> : <></>
  );
};

export default PaperViewScreen;

