import {useTheme} from '@rneui/themed';
import {Text} from '@rneui/themed';
import {floor} from 'lodash';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useWindowDimensions, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
// import {MathJaxSvg} from 'react-native-mathjax-html-to-svg';
import RenderHtml, {
  CustomBlockRenderer,
  domNodeToHTMLString, HTMLContentModel, HTMLElementModel,
} from 'react-native-render-html';
import WebView from 'react-native-webview';
import {useRecoilState, useRecoilValue} from 'recoil';

import Paper from '../common/paper';
import {SortType} from '../common/store';
import {AppContext} from '../context';
import {isWeb} from '../platform';
import {analytics} from '../platform/firebase';
import {settingsState} from '../recoil/atoms';
import {currentPaperState} from '../recoil/atoms/papers';

const MathJaxRenderer: CustomBlockRenderer = (props) => {
  // eslint-disable-next-line react/prop-types
  const domNode = props.tnode.domNode;
  const html = React.useMemo(() => domNodeToHTMLString(domNode), [domNode]);
  const eqn = /alttext="([^"]*)"/gm.exec(html)?.[1];
  const isBlock = html.includes('display="inline"');
  return (
    <View style={{
      justifyContent: isBlock ? 'center' : 'flex-start',
    }}>
      <Text>{eqn}</Text>
      {/* <SvgUri
        uri={'https://latex.codecogs.com/svg.image?' + eqn}
        onError={(e) => {
          // console.log(e);
        }}
      /> */}
    </View>
  );
};

type PaperViewPropsType = {
  paper: Paper;
};

export const HTMLPaperViewer = ({paper}: PaperViewPropsType) => {
  const {
    onPaperOrderChanged,
    savePaperAndSync,
  } = useContext(AppContext);
  const [htmlContent, setHtmlContent] = useState<string>();
  const webViewRef = useRef<WebView>(null);
  const {width} = useWindowDimensions();
  const {theme} = useTheme();
  const [settings, setSettings] = useRecoilState(settingsState);

  useEffect(() => {
    // if ((paper.dateOpened || 0) < Date.now() - 5000) {
    //   const _paper = {
    //     ...paper,
    //     dateOpened: Date.now(),
    //   };
    //   savePaperAndSync(_paper);
    // }
    analytics?.logEvent('read_paper_html', {
      paperId: paper.id,
      paperTitle: paper.title,
    });

    paper.htmlUrl && fetch(paper.htmlUrl.replace('http://ar5iv.org/abs/', 'https://ar5iv.labs.arxiv.org/html/')).then(async (response) => {
      if (response.status !== 200) {
        return;
      }
      let html = await response.text();
      if (isWeb) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(html, 'text/html');
        htmlDoc.querySelector('header')?.appendChild(
            parser.parseFromString(
                // eslint-disable-next-line max-len
                `<style>body {font-size: ${16 * paper.zoom / 100}px; overflow-x: hidden; max-width: 100%; }</style>`,
                'text/html'));
        htmlDoc.querySelectorAll('img').forEach((img) => {
          const src = img.getAttribute('src');
          if (src) {
            img.setAttribute('src', `https://ar5iv.labs.arxiv.org${src}`);
          }
        });
        setHtmlContent(htmlDoc.documentElement.outerHTML);
      } else {
        // eslint-disable-next-line max-len
        html = html.replace('<head>', `<head>\n<style>body, html {overflow-x: hidden !important; }</style>`);
        setHtmlContent(html);
      }
    });

    return () => {
      onPaperOrderChanged(SortType.ByDateOpened);
    };
  }, []);

  return (
    <View testID="html_viewer_container" style={{flex: 1}}>
      {paper && (
        paper?.htmlUrl ? (
          settings.useWebViewForHtml ? <WebView
            bounces={false}
            showsHorizontalScrollIndicator={false}
            ref={webViewRef}
            // source={isWeb ? {html: htmlContent || ''} : {uri: paper.htmlUrl}}
            source={{
              html: htmlContent || '',
              baseUrl: 'https://ar5iv.labs.arxiv.org/',
            }}
            // baseUrl: 'https://ar5iv.labs.arxiv.org',
            onNavigationStateChange={(event) => {
              // webViewRef.current?.stopLoading();
            }}
            onError={(e) => {
              console.log(e);
            }}
            onHttpError={(e) => {
              console.log(e);
            }}
            scalesPageToFit={true} /> :
          <ScrollView style={{
            padding: 16,
            backgroundColor: theme.colors?.white,
          }} bounces={false}>
            <RenderHtml
              contentWidth={width}
              source={{
                html: htmlContent || '',
                baseUrl: 'https://ar5iv.labs.arxiv.org/'}}
              customHTMLElementModels={{
                math: HTMLElementModel.fromCustomModel({
                  tagName: 'math',
                  contentModel: HTMLContentModel.block,
                }),
              }}
              renderers={{
                math: MathJaxRenderer,
              }}
              tagsStyles={{
                body: {
                  fontSize: floor(16 * paper.zoom / 100),
                  backgroundColor: theme.colors?.white,
                },
                p: {
                },
              }}
              ignoredDomTags={['header', 'footer', 'semantics']}
              enableExperimentalGhostLinesPrevention={true}
              enableExperimentalBRCollapsing={true}
            />
          </ScrollView>
        ) : (
          <Text style={{padding: 16, textAlign: 'center'}}>
            Could not retrieve the paper. Please try again later.
          </Text>
        )
      )}
    </View>
  );
};

export const HTMLPaperViewScreen = (): JSX.Element => {
  const currentPaper = useRecoilValue(currentPaperState);
  const {theme} = useTheme();
  return (
    <View style={{height: '100%', backgroundColor: theme.colors?.background}}>
      {currentPaper && <HTMLPaperViewer paper={currentPaper} />}
    </View>
  );
};

export default HTMLPaperViewScreen;
