import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
  useNavigation,
} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import * as Device from 'expo-device';
import {assign} from 'lodash';
import _ from 'lodash';
import {SourceKey} from 'paper-fetch';
import React, {useContext, useEffect, useRef} from 'react';
import {useColorScheme} from 'react-native';
import ReactNativeBlobUtil, {
  ReactNativeBlobUtilConfig,
} from 'react-native-blob-util';
import Share from 'react-native-share';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import Api from './common/api';
import {uid} from './common/api/utils';
import Collection, {
  addPaperToCollection as _addPaperToCollection,
  createNewCollection,
  isPaperInCollection,
  mergeCollection,
  removePaperFromCollection as _removePaperFromCollection,
} from './common/collection';
import Paper, {
  createNewPaper, mergePaper, normalizeTitle, PartialPaper,
  shouldFetch,
} from './common/paper';
import {RealmPaper} from './common/realm';
import {defaultAppData, defaultSettings, SortType} from './common/store';
import Tag, {createNewTag} from './common/tag';
import {ModalOptionsType} from './components/Modal';
import {
  AppContext,
  AppContextType,
  NavigationContext,
  NavigationContextType,
} from './context';
import {MainStackParamList} from './Main';
import {appNavigationRef} from './Navigation';
import {isAndroid, isWeb} from './platform';
import {analytics, auth} from './platform/firebase';
import {
  checkLocalStorageInitalized,
  localStorageGetKeys,
  localStorageRemoveItem,
  localStorageSetItem,
} from './platform/localStorage';
import {toastError} from './platform/toast';
import {
  allCollectionsState,
  allPapersState,
  allTagsState,
  currentCollectionState,
  currentPaperState,
  deviceTypeState,
  isAdminState,
  isDownloadingPaperState,
  isFetchingPaperState,
  isModalScreenVisibleState,
  isModalVisibleState,
  isReadyState,
  modalContentState,
  modalOptionsState,
  modalScreenContentState,
  modalScreenOptionsState,
  settingsState,
  showCollectionInfoState,
  sortByState,
} from './recoil/atoms';
import {realmState, useModalState} from './recoil/selectors';
import PublicCollections from './screens/PublicCollections';
import Settings from './screens/Settings';
import {SettingsStackParamList} from './screens/Settings/Navigator';

export const NavigationContextProvider = (
    {children}: {children: React.ReactNode},
): JSX.Element => {
  // const navigation =
  //   useNavigation<
  //     CompositeNavigationProp<
  //       DrawerNavigationProp<AppStackParamList, 'Drawer'>,
  //       StackNavigationProp<MainStackParamList>
  //     >
  //   >();
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>();

  // Recoil
  const useModal = useRecoilValue(useModalState);
  const setModalContent = useSetRecoilState(modalContentState);
  const setIsModalVisible = useSetRecoilState(isModalVisibleState);
  const setModalOptions = useSetRecoilState(modalOptionsState);
  const setModalScreenContent = useSetRecoilState(modalScreenContentState);
  const setIsModalScreenVisible = useSetRecoilState(isModalScreenVisibleState);
  const setModalScreenOptions = useSetRecoilState(modalScreenOptionsState);

  // AppContext
  const {isDarkMode} = useContext(AppContext);

  const showModal = (
      content: (close: () => void) => JSX.Element,
      options?: ModalOptionsType,
  ) => {
    setModalContent(content(() => setIsModalVisible(false)));
    setModalOptions(options);
    setIsModalVisible(true);
    // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  };

  const showModalScreen = (
      content: (close: () => void) => JSX.Element,
      options?: ModalOptionsType,
  ) => {
    setModalScreenContent(content(() => setIsModalScreenVisible(false)));
    setModalScreenOptions(options);
    setIsModalScreenVisible(true);
    // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  };

  const navigate = (
      screen: keyof MainStackParamList,
      params?: Record<string, unknown>,
  ) => {
    if (screen == 'Preferences' && useModal) {
      showModalScreen(() => (
        <NavigationContainer
          independent={true}
          theme={isDarkMode() ? DarkTheme : DefaultTheme}>
          <Settings initialRouteName={
            params?.screen as keyof SettingsStackParamList || 'Main'} />
        </NavigationContainer>), {
        height: 600,
        width: 600,
        contentContainerStyle: {padding: 0},
      });
    } else if (screen == 'PublicCollections' && useModal) {
      showModalScreen(() => (
        <NavigationContainer
          independent={true}
          theme={isDarkMode() ? DarkTheme : DefaultTheme}>
          <PublicCollections />
        </NavigationContainer>), {
        height: '90%',
        width: '90%',
        contentContainerStyle: {padding: 0},
      });
    } else {
      navigation.navigate(screen, params as never);
    }
  };

  return <NavigationContext.Provider value={{
    showModal,
    closeModal: () => setIsModalScreenVisible(false),
    appNavigation: appNavigationRef.current,
    navigate,
  } as NavigationContextType}>
    {children}
  </NavigationContext.Provider>;
};

const ContextProvider = ({
  render,
}: {
  render: (
    darkMode: boolean,
  ) => JSX.Element;
}): JSX.Element => {
  const colorScheme = useColorScheme();

  // Recoil
  const [settings, setSettings] = useRecoilState(settingsState);
  const [allPapers, setAllPapers] = useRecoilState(allPapersState);
  const [allCollections, setAllCollections] =
    useRecoilState(allCollectionsState);
  const [allTags, setAllTags] = useRecoilState(allTagsState);
  const [currentPaper, setCurrentPaper] = useRecoilState(currentPaperState);
  const [currentCollection, setCurrentCollection] =
    useRecoilState(currentCollectionState);
  const setShowCollectionInfo = useSetRecoilState(showCollectionInfoState);
  const setIsReady = useSetRecoilState(isReadyState);
  const setDeviceType = useSetRecoilState(deviceTypeState);
  const setIsAdmin = useSetRecoilState(isAdminState);
  const setSortBy = useSetRecoilState(sortByState);
  const [isFetching, setIsFetching] = useRecoilState(isFetchingPaperState);
  const [isDownloading, setIsDownloading] =
    useRecoilState(isDownloadingPaperState);
  const [realm, setRealm] = useRecoilState(realmState);

  // currentPaperId != currentPaper.id when UI has not been updated. Here we
  // store currentPaperId separately to avoid incorrect currentPaper.id value
  // being used.
  const currentPaperId = useRef<string>();
  const currentCollectionKey = useRef<string>();

  const isDarkMode = () =>
    settings?.theme === 'dark' ||
    (settings?.theme === 'default' && colorScheme === 'dark');

  /**
   * Subscribe to real-time changes in the server
   * @returns unsubscribe function
   */
  const onServerDataUpdated = () => {
    console.log('subscribing to server data updates');
    const unsubscribe = [
      Api.Paper.onPaperDeleted(async (_pid) => {
        // TODO: this can be optimized by changing only the affected item.
        // console.log('Paper deleted', _pid);
        // await loadAppDataFromLocalStorage();
      }),
      Api.Paper.onPaperChanged(async (_pid) => {
        // console.log('Paper changed', _pid);
        // await loadAppDataFromLocalStorage();
      }),
    ];
    return () => {
      for (const fn of unsubscribe) fn && fn();
    };
  };

  useEffect(() => {
    if (!currentCollection) return;
    setAllCollections(allCollections.map(
        (c) => c.key === currentCollection.key ? currentCollection : c));
    if (!currentCollection.publicCollectionKey &&
        currentCollection.paperIds.some((pid) => !isPaperInLibrary(pid))) {
      saveCollectionAndSync({
        ...currentCollection,
        paperIds:
          currentCollection.paperIds.filter((pid) => isPaperInLibrary(pid)),
      });
    }
  }, [currentCollection]);

  useEffect(() => {
    Device.getDeviceTypeAsync().then((type) => {
      setDeviceType(type);
    });

    const unsubscribe = auth?.onAuthStateChanged(async (u) => {
      if (!u) {
        // await auth?.signInAnonymously();
        // setIsAdmin(false);
        // onServerDataUpdated();
        return;
      }
      const isAdmin = (await Api.Profile.isAdmin() ||
        u.uid === 'ssQcZpUBt8dCTI8TRTXzilE0aWC3'); // hard coded a dev user
      setIsAdmin(isAdmin);
      loadAppData().catch((e) =>
        toastError('Could not load app data.\nPlease try again later.', e),
      );
      onServerDataUpdated();
      await analytics?.setUserId(u.uid);
      await analytics?.setUserProperty('isAdmin', isAdmin ? 'true' : 'false');
    });

    if (!realm && !isWeb) {
      Realm.open({
        path: 'realm-files/paper.realm',
        schema: [RealmPaper],
        deleteRealmIfMigrationNeeded: true,
      }).then(setRealm);
    }

    analytics?.logAppOpen && analytics?.logAppOpen();

    return () => {
      unsubscribe && unsubscribe();
      realm?.close();
      setRealm(undefined);
    };
  }, []);

  useEffect(() => {
    if (realm) loadAppDataFromLocalStorage().then(() => setIsReady(true));
  }, [realm]);

  /**
   * Load data from local storage. Data includes papers, collections, and tags.
   */
  const loadAppDataFromLocalStorage = async () => {
    const keys = await localStorageGetKeys();
    if (!settings.migratedFromLocalStorage || isWeb) {
      console.log('loading from local storage');
      const paperIds = keys.filter((key) => key.startsWith('paper:'))
          .map((key) => key.split(':')[1]);
      const papers = (
          await Promise.all(paperIds.map(
              (pid) => Api.Paper.local.loadFromLocalStorage(pid))))
          .filter((p) => !!p) as Paper[];
      if (!isWeb) {
        await Promise.all(
            papers.map((p) =>
              Api.Paper.local.save(p, p.dateModified, realm)));
        setSettings({...settings, migratedFromLocalStorage: true});
      }
      setAllPapers(Object.fromEntries(
          papers.map((p) => [p?.id, p]) as [string, PartialPaper][]));
    } else if (realm) {
      const papers = await Api.Paper.local.query(realm);
      console.log('loaded from realm', realm, papers.length);
      setAllPapers(Object.fromEntries(
          papers.map((p) => [p?.id, p]) as [string, PartialPaper][]));
    }

    const collections = keys
        .filter((key) => key.startsWith('collection:'))
        .map((key) => Api.Collection.local.get(
            key.split(':')[1]) as Promise<Collection>);
    setAllCollections(await Promise.all(collections));

    const tags = await Promise.all(
        keys
            .filter((key) => key.startsWith('tag:'))
            .map((key) => Api.Tag.load(key.slice(4)) as Promise<Tag>),
    );
    if (tags.length === 0) {
      await resetTags();
    } else {
      setAllTags(Object.fromEntries(tags.map((t) => [t.key, t])));
    }
  };

  /**
   * Load data from local storage and server.
   */
  const loadAppData = async (): Promise<void> => {
    if (!isWeb || await checkLocalStorageInitalized()) {
      await loadAppDataFromLocalStorage();
      setIsReady(true);
    } else {
      // initialize data
      const tps = await Api.Tag.getDefaultTags();
      setAllTags(
          Object.fromEntries(
              Object.entries(tps)
                  .map(([key, tp]) => [key, createNewTag({...tp, key})]),
          ),
      );
      setAllPapers({});
      await localStorageSetItem('initialized', 'done');
      setIsReady(true);
    }

    if (uid()) {
      const updated = (await Promise.all([
        Api.Paper.server.sync(realm),
        Api.Collection.sync(),
      ])).some((b) => b);
      console.log('Data syned from server. Updated: ' + updated.toString());
      // await Api.Tag.syncFromServer();
      // await Api.Settings.syncFromServer();
      if (updated) await loadAppDataFromLocalStorage();
    }

    // Download papers that are available offline
    // Object.values(allPapers).forEach(async (p) => {
    //   if (p.availableOffline &&
    //       !(await isPaperAvailableOffline(p.pdfPath))) {
    //     await download(p);
    //   }
    // });
  };

  useEffect(() => {
    console.log('Current paper changed', currentPaper?.id);

    const onCurrentPaperChanged = async () => {
      if (!currentPaper?.id) return;
      setShowCollectionInfo(false);
      // update allPapers if the paper was modified
      // if (allPapers[currentPaper.id]) {
      //   if (currentPaper.dateModified !==
      //   allPapers[currentPaper.id].dateModified) {
      //     setAllPapers({
      //       ...allPapers,
      //       [currentPaper.id]: currentPaper as PartialPaper,
      //     });
      //   }
      // }
    };

    onCurrentPaperChanged();
    currentPaperId.current = currentPaper?.id;
  }, [currentPaper]);

  /**
   * change the current paper
   * @param p - new current paper
   */
  async function changeCurrentPaper(p: PartialPaper) {
    if (!p.id) throw Error('Current paper must have an id');
    const localPaper = await Api.Paper.load(p.id, realm);
    const sources = await Api.Paper.local.loadSources(p.id, realm);
    setCurrentPaper({
      ...p,
      ...localPaper,
      sources,
    } as Paper);
  }

  // useEffect(() => {
  //   // if the paper in allPapers is newer, update currentPaper
  //   if (!currentPaper?.id || !allPapers[currentPaper.id]) return;
  //   if ((currentPaper.dateModified || 0) <
  //       (allPapers[currentPaper.id].dateModified || 0)) {
  //     // TODO: unneccessary to update current paper
  //     changeCurrentPaper(allPapers[currentPaper.id]);
  //   }
  // }, [allPapers]);

  useEffect(() => {
    currentCollectionKey.current = currentCollection?.key;
    if (!currentPaper?.id) return;
  }, [currentCollection]);

  const onPaperOrderChanged = (sortType?: SortType) => {
    if (sortType && sortType !== settings.paperList.sortBy) return;
    console.log('order changed', sortType);
    // setPaperListItems(sort(paperListItems, settings.paperList.sortBy));
  };

  /**
   * fetch paper from sources
   * @param paper - paper to fetch
   * @param sources - sources to fetch from
   * @param forceRefresh - force refresh
   * @param updateProgressFn - update progress function
   * @returns updated paper
   */
  async function fetchPaper(
      paper: Paper,
      sources?: SourceKey[],
      forceRefresh?: boolean,
      updateProgressFn?: (paper: Paper, msg: string) => void,
      updatePaperFn?: (paper: Paper) => void,
  ) {
    if (!forceRefresh &&
        !shouldFetch(paper, sources || settings.fetchPaperSources)) {
      return paper;
    }
    try {
      const oldPaper = _.cloneDeep(paper);
      setIsFetching({
        ...isFetching,
        [paper.id]: true,
      });
      const updatedPaper = await Api.Paper.fetch(
          paper,
          sources || settings.fetchPaperSources,
          forceRefresh,
          updateProgressFn,
      );
      setIsFetching({
        ...isFetching,
        [paper.id]: false,
      });

      // If the paper is changed
      if (updatedPaper.dateModified !== oldPaper.dateModified) {
        console.log('Paper metadata updated', updatedPaper.id);
        if (isPaperInLibrary(oldPaper.id) && oldPaper.id !== updatedPaper.id) {
          console.log('Paper id changed', oldPaper.id, updatedPaper.id);
          await Api.Paper.remove(oldPaper.id, realm);
          setAllPapers({
            ...Object.fromEntries(Object.entries(allPapers)
                .filter(([key, _]) => key !== oldPaper.id)),
            [updatedPaper.id]: updatedPaper as PartialPaper,
          });

          const updateCollection = (c: Collection) => ({
            ...c,
            paperIds: [
              ...c.paperIds.filter((p) => p !== oldPaper.id),
              updatedPaper.id,
            ],
          } as Collection);

          // If paper belongs to a collection, update the collection
          const newAllCollections = await Promise.all(
              allCollections.map((c) => {
                if (isPaperInCollection(oldPaper.id, c)) {
                  return saveCollectionAndSync(updateCollection(c));
                } else return c;
              }),
          );
          setAllCollections(newAllCollections);
          // Update current collection & paper if needed
          if (currentCollection &&
            isPaperInCollection(oldPaper.id, currentCollection)) {
            setCurrentCollection(updateCollection(currentCollection));
          }
          await Api.Paper.local.save(updatedPaper, undefined, realm);
          await Api.Paper.server.save(updatedPaper);
        } else if (isPaperInLibrary(updatedPaper.id)) {
          await savePaperAndSync(updatedPaper);
          updatePaperFn && updatePaperFn(updatedPaper);
        } else {
          updatePaperFn ?
            updatePaperFn(updatedPaper) : setCurrentPaper(updatedPaper);
        }

        // Sort the list if necessary
        onPaperOrderChanged(SortType.ByDateModified);
        if (oldPaper.title !== updatedPaper.title) {
          onPaperOrderChanged(SortType.ByTitle);
        }
        if (oldPaper.numCitations !== updatedPaper.numCitations) {
          onPaperOrderChanged(SortType.ByCitation);
        }
        if (oldPaper.year !== updatedPaper.year) {
          onPaperOrderChanged(SortType.ByYear);
        }
      }

      return updatedPaper;
    } catch (e) {
      toastError(`Error fetching paper "${paper.title}"`, e);
      return paper;
    }
  }

  /**
   * Sync a local collection with a remote collection based on dateModified.
   * @param collection - a collection
   */
  async function syncCollection(collection: Collection): Promise<void> {
    try {
      const remoteCollection = await Api.Collection.server.get(collection.key);

      if (remoteCollection?.dateModified === collection.dateModified) {
        console.log('No sync required');
        return;
      }

      const isLocalCollectionNewer = !remoteCollection?.dateModified ||
        remoteCollection.dateModified < collection.dateModified;
      const updatedCollection = isLocalCollectionNewer ?
        mergeCollection(remoteCollection, collection) :
        mergeCollection(collection, remoteCollection);

      if (collection.key === currentCollectionKey.current) {
        setCurrentCollection(updatedCollection);
      } else {
        setAllCollections([
          ...allCollections.filter((c) => c.key !== collection.key),
          collection,
        ]);
      }
      await Api.Collection.local.save(updatedCollection, true);
      await Api.Collection.server.save(updatedCollection);
    } catch (_e) {
      // do nothing
    }
  }

  /**
   * Save paper first, then sync. This is to ensure that the local paper is
   * the newer when syncing. App state is updated after finishing.
   * @param p - a paper
   * @returns - updated paper
   */
  async function savePaperAndSync(p: Paper, sync = true) {
    if (!isPaperInLibrary(p.id)) console.warn('Paper not in library');
    const paper = await Api.Paper.local.save(p, undefined, realm);

    if (sync) {
      try {
        const remotePaper = await Api.Paper.server.get(paper.id);
        if (!paper.dateModified) throw new Error('Paper has no dateModified');
        const updatedPaper = mergePaper(remotePaper, paper);
        if (paper.id === currentPaperId.current) setCurrentPaper(updatedPaper);
        setAllPapers({
          ...allPapers,
          [paper.id]: Api.Paper.getPartialPaper(paper),
        });
        await Api.Paper.local.save(
            updatedPaper, updatedPaper.dateModified, realm);
        await Api.Paper.server.save(updatedPaper);
      } catch (_e) {
        if (paper.id === currentPaperId.current) setCurrentPaper(paper);
        else setAllPapers({...allPapers, [paper.id]: paper as PartialPaper});
      }
    } else {
      if (paper.id === currentPaperId.current) setCurrentPaper(paper);
      setAllPapers({
        ...allPapers,
        [paper.id]: Api.Paper.getPartialPaper(paper),
      });
    }

    return paper;
  }

  /**
   * Save collection locally first, then sync with the server.
   * @param collection - a collection
   * @returns - updated collection
   */
  async function saveCollectionAndSync(collection: Collection) {
    const c = await Api.Collection.local.save(collection);
    await syncCollection(c);
    return c;
  }

  /**
   * check if paper's pdf is available offline
   * @param p - a paper
   * @returns true if pdf is available offline
   */
  async function isPaperAvailableOffline(pdfPath?: string) {
    if (isWeb) return false;
    const absolutePath = getPdfAbsolutePath(pdfPath);
    return (absolutePath &&
      await ReactNativeBlobUtil.fs.exists(absolutePath));
  }

  /**
   * Download a paper to internal storage (iOS/android only)
   * @param p - a paper
   */
  async function download(p: PartialPaper) {
    console.log('Downloading paper ' + p.id + '...');
    if (isWeb) return;
    if (!isPaperInLibrary(p.id)) return;
    if (isDownloading[p.id]) return;

    const _download = async (
        paper: PartialPaper,
        onProgress?: (received: number, total: number) => void) => {
      const _paper = await Api.Paper.local.load(paper.id, realm);
      console.log('Downloading from ' + _paper?.pdfUrl);
      if (!_paper || !_paper.pdfUrl || !_paper.title) return _paper;
      const fn = normalizeTitle(
          _paper.title.toLowerCase().replace(/\W/g, ' ')).replace(/\s/g, '_');
      const configOptions: ReactNativeBlobUtilConfig = {
        fileCache: true,
        path: ReactNativeBlobUtil.fs.dirs.DocumentDir + `/Papers/${fn}.pdf`,
        timeout: 10000,
        followRedirect: false,
        indicator: true,
        IOSBackgroundTask: true,
      };
      try {
        await ReactNativeBlobUtil.config(configOptions)
            .fetch('GET', _paper.pdfUrl)
            .progress({count: 10}, onProgress || (() => {/**/}));
        return await savePaperAndSync({
          ..._paper,
          pdfPath: `Papers/${fn}.pdf`,
          availableOffline: true,
        } as Paper);
      } catch (e) {
        setIsDownloading({...isDownloading, [p.id]: false});
        toastError(`Error downloading paper: ${(e as Error).message}`, e);
      }
    };

    setIsDownloading({...isDownloading, [p.id]: true});
    await _download(p);
    setIsDownloading({...isDownloading, [p.id]: false});
  }

  /**
   * get the local absolute path of a paper's pdf
   * @param p - a paper
   * @returns absolute path
   */
  function getPdfAbsolutePath(pdfPath?: string) {
    if (!pdfPath) return undefined;
    return ReactNativeBlobUtil.fs.dirs.DocumentDir + '/' + pdfPath;
  }

  /**
   * Share paper's pdf
   * @param filePath - path to the file
   */
  async function sharePdf(p: Paper) {
    if (!p.pdfPath) throw new Error('PDF has not been downloaded.');
    const pdfPath = getPdfAbsolutePath(p.pdfPath);
    try {
      await Share.open({
        type: 'application/pdf',
        url: isAndroid ? 'file://' + pdfPath : pdfPath,
      });
      analytics?.logShare({
        content_type: 'pdf',
        item_id: p.id,
        method: '',
      });
    } catch (e) {
      // do nothing
    }
  }

  /**
   * Remove saved pdf file
   * @param paper - a paper
   * @returns - updated paper
   */
  async function removePdf(pid: string) {
    const paper = await Api.Paper.local.load(pid, realm);
    if (!paper) throw Error('Paper must be in the library');
    if (!paper.pdfPath) return paper;
    try {
      const absolutePath = getPdfAbsolutePath(paper.pdfPath);
      if (absolutePath) await ReactNativeBlobUtil.fs.unlink(absolutePath);
    } catch (e) {
      // do nothing
    }
    await savePaperAndSync({
      ...paper,
      pdfPath: undefined,
      availableOffline: false,
    });
  }

  /**
   * Share paper's url
   */
  async function share() {
    const paper = currentPaper;
    if (!paper || !paper.pdfUrl) throw new Error('No URL found.');
    Share.open({
      title: 'PaperShelf',
      message: paper.title,
      url: paper.pdfUrl,
      failOnCancel: false,
    }).then(() => {
      analytics?.logShare({
        content_type: 'paper',
        item_id: paper.id,
        method: '',
      });
    }).catch((err) => {
      const error = err as Error;
      toastError(`Could not share the paper: ${error.message}`, err);
    });
  }

  /**
   * Save a public collection to the library
   */
  async function savePublicCollection(collection: Collection) {
    // Create new collection with different key but linked to the public
    // collection.
    const c = createNewCollection({
      publicCollectionKey: collection.key,
      name: collection.name,
      description: collection.description,
      publicInfo: collection.publicInfo,
    });
    await Api.Collection.local.save(c, true);
    await Api.Collection.server.save(c);
    setAllCollections([
      ...allCollections.filter(
          (_c) => _c.key !== collection.key), c,
    ]);
    analytics?.logEvent('save_public_collection', {
      collectionId: c.key,
      collectionName: c.name,
    });
  }

  /**
   * check if a paper is in the library
   * @param paper - a paper
   * @returns - true if paper is in library
   */
  function isPaperInLibrary(pid: string) {
    return !!allPapers[pid];
  }

  /**
   * check if a paper is the current paper
   * @param pid - paper id
   * @returns - true if paper is the current paper
   */
  function isCurrentPaper(paper: Paper) {
    return paper.id === currentPaperId.current;
  }

  /**
   * Create a new collection
   * @param title - title of the new collection
   * @returns
   */
  async function newCollection(title: string) {
    const c = createNewCollection({name: title});
    setAllCollections([...allCollections, c]);
    saveCollectionAndSync(c);
    return c;
  }

  /**
   * add a paper to the library
   * @param p - a paper
   * @returns updated paper
   */
  async function addPaperToLibrary(p: PartialPaper | Paper) {
    if (isPaperInLibrary(p.id)) return;
    const updatedPaper = await Api.Paper.local.save({
      ...createNewPaper(), ...p}, undefined, realm);
    setAllPapers({
      ...allPapers,
      [p.id]: Api.Paper.getPartialPaper(updatedPaper),
    });
    await Api.Paper.server.save(updatedPaper);
    console.log('paper added to library', p.id);
    await analytics?.logEvent('add_paper_to_library', {
      paperId: p.id,
      paperTitle: p.title,
    });
  }

  /**
   * add a paper to a collection
   * @param p - a paper
   * @param c - a collection
   */
  async function addPaperToCollection(p: Paper, c: Collection) {
    await addPaperToLibrary(p);
    const newC = _addPaperToCollection(p.id, c);
    await saveCollectionAndSync(newC);
    await savePaperAndSync(p);
    await analytics?.logEvent('add_paper_to_collection', {
      paperId: p.id,
      collectionName: c.name});
  }

  /**
   * remove a paper from a collection
   * @param p - a paper
   * @param c - a collection
   */
  async function removePaperFromCollection(pid: string, c: Collection) {
    const newC = _removePaperFromCollection(pid, c);
    setAllCollections(
        allCollections.map((_c) => _c.key === newC.key ? newC : _c));
    await saveCollectionAndSync(newC);
  }

  /**
   * remove a paper from the library
   * @param paper - a paper
   */
  async function removePaperFromLibrary(pid: string | string[]) {
    const deletedIds = Array.isArray(pid) ? pid : [pid];

    await Promise.all(
        deletedIds.map(async (pid) => {
          removePdf(pid);
          Api.Paper.remove(pid, realm);
        }));

    setAllPapers(
        Object.fromEntries(Object.entries(allPapers)
            .filter(([id]) => !deletedIds.includes(id))));

    // remove paper from all collections
    const _allCollections = await Promise.all(
        allCollections.map(async (c) => {
          // paper remains in public collection
          if (c.publicCollectionKey) return c;
          let newC = c;
          for (const pid of deletedIds) {
            if (isPaperInCollection(pid, c)) {
              newC = _removePaperFromCollection(pid, newC);
            }
          }
          return saveCollectionAndSync(newC);
        }),
    );
    setAllCollections(_allCollections);
    setCurrentCollection(
        _allCollections.find((c) => c.key === currentCollection?.key));
  }

  /**
   * reset tags to default
   * @returns - all tags
   */
  async function resetTags() {
    try {
      const tps = await Api.Tag.getDefaultTags();
      const _allTags = assign(
          {},
          allTags,
          Object.fromEntries(
              Object.entries(tps).map(([key, tp]) => [
                key,
                createNewTag({...tp, key}),
              ]),
          ),
      );
      await Api.Tag.removeAll();
      setAllTags(_allTags);
      await Promise.all(Object.values(_allTags).map((t) => Api.Tag.save(t)));
      return _allTags;
    } catch (e) {
      toastError('Error loading default tags.', e);
      return allTags;
    }
  }

  /**
   * Clear all tags
   */
  async function clearTags() {
    await Api.Tag.removeAll();
    setAllTags({});
  }

  /**
   * Clear all local data
   */
  async function clearLocalData() {
    setAllPapers(defaultAppData.papers);
    setAllCollections(defaultAppData.collections);
    await resetTags();
    setSettings(_.cloneDeep(defaultSettings));

    if (isWeb) location.reload();

    setCurrentPaper(null);
    setCurrentCollection(undefined);
    const keys = await localStorageGetKeys();
    await Promise.all(
        keys.map((key) => {
          if (!['settings'].includes(key)) return localStorageRemoveItem(key);
        }),
    );
    realm?.write(() => {
      Realm.deleteFile({
        path: 'realm-files/paper.realm',
        schema: [RealmPaper],
      });
    });
  }

  /**
   * Delete a collection
   * @param c - collection to delete
   */
  async function deleteCollection(c: Collection) {
    await Api.Collection.local.remove(c);
    await Api.Collection.server.remove(c);
    setAllCollections(allCollections.filter((_c) => _c.key !== c.key));
  }

  useEffect(() => {
    Api.Settings.save(settings);
    setSortBy(settings.paperList.sortBy);
  }, [settings]);

  return settings ? (
    <AppContext.Provider
      value={
        {
          addPaperToCollection,
          addPaperToLibrary,
          changeCurrentPaper,
          clearLocalData,
          clearTags,
          deleteCollection,
          download,
          fetchPaper,
          isPaperInLibrary,
          isCurrentPaper,
          loadAppData,
          newCollection,
          onPaperOrderChanged,
          removePaperFromCollection,
          removePaperFromLibrary,
          removePdf,
          resetTags,
          saveCollectionAndSync,
          savePaperAndSync,
          savePublicCollection,
          share,
          sharePdf,
          isDarkMode,
          isPaperAvailableOffline,
        } as AppContextType
      }
    >
      {render(
          isDarkMode(),
      )}
    </AppContext.Provider>
  ) : (
    <></>
  );
};

export default ContextProvider;
