import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {Text, useTheme} from '@rneui/themed';
import React, {useContext, useEffect, useState} from 'react';
import {Linking, Pressable, View} from 'react-native';
import {useRecoilValue} from 'recoil';

import {isPaperInCollection} from '../../common/collection';
import Paper from '../../common/paper';
import ActivityIndicator from '../../components/ActivityIndicator';
import {ItemKind, PreferencesScreen} from '../../components/Preferences';
import {AppContext, NavigationContext} from '../../context';
import {MainStackParamList} from '../../Main';
import {isWeb} from '../../platform';
import {dialog} from '../../platform/dialog';
import {analytics} from '../../platform/firebase';
import {
  IconAddCollection,
  IconAddLibrary,
  IconAlias,
  IconDelete,
  IconDownload,
  IconHighlight,
  IconHtml,
  IconMenu,
  IconNoStar,
  IconOpenUrl,
  IconPdf,
  IconProps,
  IconRemoveCollection,
  IconRemoveFile,
  IconShare,
  IconShareFile,
  IconStar,
} from '../../platform/icons';
import {currentCollectionState} from '../../recoil/atoms';
import {
  allPapersState, isDownloadingPaperState,
} from '../../recoil/atoms/papers';
import {
  isElectronState, isPadState, isPhoneState,
} from '../../recoil/selectors';
import {PaperInfoContext} from './ContextProvider';

const SimpleButton = ({testID, title, onPress, icon, disabled = false}: {
  testID?: string;
  title: string,
  onPress?: () => void,
  icon: (props: IconProps) => React.ReactNode,
  disabled?: boolean,
}) => {
  const {theme} = useTheme();
  return <Pressable
    testID={testID}
    onPress={onPress} style={{flex: 1}} disabled={disabled}>
    {({pressed, hovered}) => <View style={{
      padding: 12,
      backgroundColor: pressed ?
          theme.colors.backgroundPressed :
          (hovered ? theme.colors.backgroundHovered : theme.colors.white)}}>
      {icon({
        size: 24,
        color: disabled ? theme.colors.disabled : theme.colors.black,
      })}
      <Text style={{
        width: '100%',
        textAlign: 'center',
        paddingTop: 8,
        color: disabled ? theme.colors.disabled :
        theme.colors.black}}>{title}</Text>
    </View>}
  </Pressable>;
};

const Main = () => {
  const {
    savePaperAndSync,
    share,
    download,
    addPaperToLibrary,
    removePaperFromCollection,
    addPaperToCollection,
    sharePdf,
    removePdf,
    removePaperFromLibrary,
    isPaperAvailableOffline,
  } = useContext(AppContext);
  const navigation =
    useNavigation<StackNavigationProp<MainStackParamList, 'PaperInfo'>>();
  const {theme} = useTheme();
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const [isAvailableOffline, setIsAvailableOffline] = useState<boolean>(false);
  const {paper, setPaper, onViewPaper} = useContext(PaperInfoContext);
  const {navigate} = useContext(NavigationContext);

  // recoil
  const isPhone = useRecoilValue(isPhoneState);
  const isPad = useRecoilValue(isPadState);
  const isElectron = useRecoilValue(isElectronState);
  const allPapers = useRecoilValue(allPapersState);
  const currentCollection = useRecoilValue(currentCollectionState);
  const isDownloading = useRecoilValue(isDownloadingPaperState);

  // context
  const {isPaperInLibrary} = useContext(AppContext);

  // const summarize = (items?: string[], maxItems = 3) => {
  //   if (!items || items.length === 0) return 'None';
  //   const displayItems =
  //     items.length > maxItems ? items.slice(0, maxItems) : items;
  //   return (
  //     displayItems.join(', ') +
  //     (items.length > maxItems ?
  //       `, and ${items.length - maxItems} other` : '')
  //   );
  // };

  useEffect(() => {
    isPaperAvailableOffline(paper.pdfPath).then(setIsAvailableOffline);
  }, [paper]);

  return paper ? (
    <View style={{height: '100%'}}>
      <PreferencesScreen
        testID="pref_paper_info"
        padItem={false}
        sections={[
          {
            id: 'info',
            title: '',
            items: () => [
              {
                id: 'title',
                title: 'Header',
                kind: ItemKind.Custom,
                pressable: false,
                component: (
                  <View
                    style={{
                      alignContent: 'center',
                      justifyContent: 'center',
                      padding: 16,
                      flex: 1,
                    }}
                  >
                    <Text
                      h4={true}
                      style={{
                        marginBottom: 8, marginTop: 16, textAlign: 'center'}}
                    >
                      {paper?.title}
                    </Text>
                    <Text style={{textAlign: 'center', marginBottom: 8}}>
                      {paper.authorFull}
                    </Text>
                    <Text style={{textAlign: 'center'}}>
                      {[paper.year,
                        paper.numCitations ?
                        `Cited by ${paper.numCitations.toLocaleString()}` :
                        undefined]
                          .filter((field) => !!field).join(' | ')}
                    </Text>
                  </View>
                ),
              },
              {
                id: 'read',
                kind: ItemKind.Custom,
                component: <View style={{width: '100%', flexDirection: 'row'}}>
                  {!allPapers[paper.id] && <SimpleButton
                    testID="btn_add_to_library"
                    title="Library"
                    onPress={() => {
                      if (paper) {
                        addPaperToLibrary(paper);
                      }
                    }} icon={IconAddLibrary} />}
                  <SimpleButton
                    testID='btn_pdf' title='Read'
                    disabled={!paper.pdfUrl}
                    onPress={onViewPaper} icon={IconPdf} />
                  {isPaperInLibrary(paper.id) && <SimpleButton
                    testID='btn_star'
                    title={paper.tags.includes('starred') ? 'Starred' : 'Star'}
                    onPress={async () => {
                      const newPaper = {
                        ...paper,
                        customTags: paper.customTags.includes('starred') ?
                          paper.customTags.filter((t) => t !== 'starred') :
                          [...paper.customTags, 'starred'],
                      } as Paper;
                      await savePaperAndSync(newPaper);
                    }}
                    icon={paper.tags.includes('starred') ?
                      IconStar : IconNoStar} />}
                  {!isWeb &&
                    <SimpleButton
                      title="Share"
                      icon={IconShare}
                      disabled={!paper.pdfUrl}
                      onPress={share} />}
                  {!isWeb && isPaperInLibrary(paper.id) &&
                    paper.pdfUrl &&
                    <SimpleButton
                      title={isDownloading[paper.id] ?
                        `${downloadProgress.toFixed(0)}%` :
                        (isAvailableOffline ? 'Saved' : 'Save')}
                      disabled={isDownloading[paper.id] || !!paper.pdfPath}
                      icon={isDownloading[paper.id] ?
                        ActivityIndicator : IconDownload}
                      onPress={() => download(paper, (r, t) => {
                        console.log('download progress', r, t);
                        setDownloadProgress(r * 100 / t);
                      })} />}
                </View>,
              },
              {
                id: 'TL;DR',
                kind: ItemKind.Text,
                hidden: !paper.tldr,
                title: (
                  <Text
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 8,
                    }}
                  >
                    TL;DR
                  </Text>
                ),
                subTitle: paper.tldr,
                numberOfLines: 20,
                pressable: false,
              },
              {
                id: 'abstract',
                kind: ItemKind.Text,
                hidden: !paper.abstract,
                title: (
                  <Text
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 8,
                    }}
                  >
                    Abstract
                  </Text>
                ),
                subTitle: paper.abstract || <ActivityIndicator />,
                numberOfLines: 20,
                pressable: false,
              },
              {
                id: 'outline',
                kind: ItemKind.List,
                hidden: !paper.pdfInfo?.outline ||
                  paper.pdfInfo?.outline.length === 0,
                title: 'Outline',
                icon: IconMenu,
                subItems: () => (paper.pdfInfo?.outline?.map((po) => ({
                  id: 'outline_' + po.title,
                  kind: ItemKind.Button,
                  title: po.title,
                })) || []),
              },
              {
                id: 'notes',
                kind: ItemKind.Input,
                hidden: !paper.note,
                editable: false,
                title: (
                  <Text
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 8,
                    }}
                  >
                    Notes
                  </Text>
                ),
                value: paper.note,
                numberOfLines: 20,
                pressable: false,
              },
              {
                id: 'highlights',
                kind: ItemKind.Menu,
                hidden: !isPaperInLibrary(paper.id) || !paper.pdfHighlights,
                title: `Notes & Highlights (${paper.pdfHighlights?.length})`,
                icon: IconHighlight,
                onPress: () => {
                  navigation.navigate('Notes');
                },
              },
              {
                id: 'alias',
                kind: ItemKind.Input,
                title: 'Short Name',
                icon: IconAlias,
                subTitle: 'Choose an abbreviated name for this paper or leave' +
                  ' empty.',
                value: paper.customAlias || paper.alias,
                inline: true,
                hidden: !isPaperInLibrary(paper.id) && !paper.alias,
                onChange: (val) => {
                  if (!paper) return;
                  const newPaper = {
                    ...paper,
                    customAlias: val as string,
                  };
                  setPaper(newPaper);
                  analytics?.logEvent('set_paper_alias');
                  savePaperAndSync(newPaper);
                },
              },
            ],
          },
          {
            id: 'actions',
            title: '',
            items: () => [
              {
                id: 'html',
                kind: ItemKind.Button,
                title: 'Read in HTML',
                icon: IconHtml,
                disabled: !paper.htmlUrl,
                onPress: () => {
                  if (!paper.htmlUrl) return;
                  if (isWeb && !isElectron) {
                    window.open(paper.htmlUrl, '_blank');
                  } else {
                    navigate('HTMLPaperView');
                  }
                },
              },
              {
                id: 'open_in_browser',
                kind: ItemKind.Button,
                title: 'Open in Browser',
                icon: IconOpenUrl,
                disabled: !paper.pdfUrl,
                onPress: () => {
                  if (!paper.pdfUrl) return;
                  Linking.openURL(paper.pdfUrl);
                },
              },
              {
                id: 'remove_from_collection',
                kind: ItemKind.Button,
                icon: IconRemoveCollection,
                hidden: !currentCollection ||
                  !isPaperInCollection(paper.id, currentCollection) ||
                  currentCollection.isPublic ||
                  !!currentCollection.publicCollectionKey,
                title: 'Remove from ' + currentCollection?.name,
                onPress: () => {
                  if (paper && currentCollection) {
                    removePaperFromCollection(paper.id, currentCollection);
                  }
                },
              },
              ...(currentCollection ?
                (isPaperInCollection(paper.id, currentCollection) ?
                [] :
                [
                  {
                    id: 'add_to_collection',
                    kind: ItemKind.Button,
                    icon: IconAddCollection,
                    title: 'Add to ' + currentCollection.name,
                    onPress: () => {
                      if (paper && currentCollection) {
                        addPaperToCollection(paper, currentCollection);
                      }
                    },
                  },
                ]) : []),
              ...(isPaperInLibrary(paper.id) ?
                [
                  {
                    id: 'share_pdf',
                    kind: ItemKind.Button,
                    title: 'Share PDF',
                    icon: IconShareFile,
                    hidden: !paper.pdfPath || !(isPhone || isPad),
                    onPress: async () => {
                      if (!paper) return;
                      await sharePdf(paper);
                    },
                  },
                  {
                    id: 'remove_from_downloaded',
                    kind: ItemKind.Button,
                    title: 'Remove Saved PDF',
                    hidden: !paper.pdfPath || !(isPhone || isPad),
                    icon: IconRemoveFile,
                    onPress: async () => {
                      if (!paper) return;
                      await removePdf(paper.id);
                    },
                  },
                  {
                    id: 'delete',
                    kind: ItemKind.Button,
                    title: 'Remove from Library',
                    hidden: !isPaperInLibrary(paper.id),
                    onPress: () => {
                      dialog(
                          'Remove from Library',
                          'Do you want to remove this paper from the library?',
                          [
                            {
                              text: 'Remove',
                              style: 'destructive',
                              onPress: () => {
                                if (!paper) return;
                                removePaperFromLibrary(paper.id);
                                if (navigation.canGoBack()) {
                                  navigation.goBack();
                                }
                              },
                            },
                            {text: 'Cancel'},
                          ],
                      );
                    },
                    color: theme.colors.error,
                    icon: IconDelete,
                  },
                ] :
                []),
            ],
          },
        ]}
      />
    </View>
  ) : (
    <Text>No paper selected</Text>
  );
};

export default Main;
