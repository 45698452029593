import {
  DrawerContentComponentProps,
  DrawerNavigationProp,
} from '@react-navigation/drawer';
import {
  CompositeNavigationProp,
  useNavigation,
} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {Button, Divider} from '@rneui/base';
import {Badge, ListItem, Text, useTheme} from '@rneui/themed';
import React, {useContext, useState} from 'react';
import {LayoutAnimation, Pressable, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {useRecoilState, useRecoilValue} from 'recoil';

import {isLoggedIn} from '../common/api/utils';
import {AppContext, NavigationContext} from '../context';
import {MainStackParamList} from '../Main';
import {AppStackParamList} from '../Navigation';
import {
  IconAccount,
  IconAdd,
  IconCollapsed,
  IconConfig,
  IconExpanded,
  IconLogin,
  IconManageCollection,
  IconPublicCollection,
  IconSync,
} from '../platform/icons';
import {toastSuccess} from '../platform/toast';
import {
  allCollectionsState,
  currentCollectionState,
} from '../recoil/atoms';
import {allPapersState} from '../recoil/atoms/papers';
import {
  isWebState,
} from '../recoil/selectors';
import ActivityIndicator from './ActivityIndicator';
import {Prompt} from './Prompt';
import {ScreenContainer} from './ScreenContainer';


const SyncButton = () => {
  const {
    loadAppData,
  } = useContext(AppContext);
  const {navigate} = useContext(NavigationContext);
  const isWeb = useRecoilValue(isWebState);
  const [syncing, setSyncing] = useState<boolean>(false);

  return <Button
    testID='btn_sync'
    type='clear'
    disabled={syncing}
    icon={syncing ? <ActivityIndicator /> : <IconSync size={20} />} style={{
      paddingTop: isWeb ? 16 : 4,
    }}
    onPress={async () => {
      if (!isLoggedIn()) {
        navigate('Preferences', {screen: 'Account'});
      } else {
        setSyncing(true);
        await loadAppData();
        setSyncing(false);
        toastSuccess('Synced successfully');
      }
    }}
  />;
};


const CollectionListItem = ({
  testID,
  title,
  onPress,
  selected = false,
  icon,
  badgeText,
}: {
  testID?: string;
  title: string;
  onPress: () => void;
  selected?: boolean;
  icon?: React.ReactNode;
  badgeText?: string;
}) => {
  const {theme} = useTheme();
  return (
    <Pressable
      testID={testID}
      onPress={onPress}
    >
      {({pressed, hovered}) => (
        <ListItem
          containerStyle={{
            backgroundColor:
              pressed ? theme.colors.backgroundPressed : (
                hovered ? theme.colors.backgroundHovered :
                selected ? theme.colors.backgroundHighlight : theme.colors.white
              ),
          }}
        >
          {icon}
          <ListItem.Content>
            <View style={{
              flexDirection: 'row', width: '100%', paddingRight: 16,
              alignItems: 'center'}}>
              <ListItem.Title style={{flex: 1}} numberOfLines={1}>
                {title}
              </ListItem.Title>
              {badgeText && <View testID="collection_badge"><Badge
                containerStyle={{flex: 0}}
                badgeStyle={{height: 24, padding: 0}}
                textStyle={{fontSize: 12}}
                value={badgeText} /></View>}
            </View>
          </ListItem.Content>
        </ListItem>
      )}
    </Pressable>
  );
};

type DrawerSectionProps = {
  title: string;
  items: JSX.Element[];
};

const DrawerSection = ({title, items}: DrawerSectionProps) => {
  const {theme} = useTheme();
  const [collapsed, setCollapsed] = React.useState(false);

  return items.length > 0 ? (
    <View>
      {title && <Pressable onPress={() => {
        setCollapsed(!collapsed);
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      }} style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 4,
        height: 32,
      }}>
        <View style={{width: 16}}>
          {collapsed ?
          <IconCollapsed size={12} color={theme.colors.disabled} /> :
          <IconExpanded size={12} color={theme.colors.disabled} />}
        </View>
        <Text
          style={{
            color: theme.colors?.disabled,
            fontSize: 12,
            paddingHorizontal: 4,
          }}
        >
          {title}
        </Text>
      </Pressable>}
      {!collapsed && items}
    </View>
  ) : (
    <></>
  );
};

const CollectionDrawer = ({
  navigation,
}: DrawerContentComponentProps): JSX.Element => {
  return (
    <ScreenContainer
      testID="collection_drawer"
      edges={['top', 'bottom', 'left']}>
      <CollectionList toggleDrawer={navigation.toggleDrawer} />
    </ScreenContainer>
  );
};

type CollectionListProps = {
  toggleDrawer: () => void;
};

export const CollectionList = ({toggleDrawer}: CollectionListProps) => {
  const {showModal, navigate} = useContext(NavigationContext);
  const {newCollection} = useContext(AppContext);
  const isWeb = useRecoilValue(isWebState);
  const allPapers = useRecoilValue(allPapersState);
  const allCollections = useRecoilValue(allCollectionsState);
  const [currentCollection, setCurrentCollection] =
    useRecoilState(currentCollectionState);
  const {theme} = useTheme();
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        DrawerNavigationProp<AppStackParamList, 'Drawer'>,
        StackNavigationProp<MainStackParamList>
      >
    >();

  return (
    <View
      testID='drawer'
      style={{flex: 1, backgroundColor: theme.colors?.white}}
    >
      <View style={{
        height: isWeb ? 64 : 48,
        flexDirection: 'row',
        paddingHorizontal: 16,
        backgroundColor: theme.colors.white,
      }}>
        <Text style={{
          paddingTop: isWeb ? 20 : 8,
          fontSize: 20,
          fontWeight: 'bold',
          flex: 1,
        }}>Your Library</Text>
        <Button
          testID='btn_edit_collections'
          type='clear' icon={<IconManageCollection size={20} />} style={{
            paddingTop: isWeb ? 16 : 4,
          }}
          onPress={() => {
            navigation.navigate('Preferences', {screen: 'Collections'});
          }}
        />
        <SyncButton />
      </View>
      {currentCollection && currentCollection?.isPublic && <CollectionListItem
        title={currentCollection.name}
        onPress={() => {
          toggleDrawer();
        }}
        selected={true}
        badgeText={currentCollection.publicInfo?.numPapers?.toString()}
      />}
      <ScrollView style={{flex: 1, backgroundColor: theme.colors.white}}>
        <DrawerSection
          title=""
          items={[
            <CollectionListItem
              key="all"
              title="All"
              onPress={() => {
                toggleDrawer();
                setCurrentCollection(undefined);
              }}
              selected={!currentCollection}
              badgeText={Object.keys(allPapers).length.toString()}
            />,
            <CollectionListItem
              key="more"
              title="Browse..."
              onPress={() => {
                navigate('PublicCollections');
              }}
              icon={<IconPublicCollection />}
            />,
          ]} />
        <DrawerSection
          title="Starred"
          items={allCollections
              .filter((c) => !c.hidden)
              .filter((c) => c.starred)
              .sort((a, b) =>
                (a.order - b.order) || a.name.localeCompare(b.name))
              .map((c) => (
                <CollectionListItem
                  testID='drawer_collection_item_starred'
                  key={c.key}
                  title={c.name}
                  badgeText={
                    c.publicInfo?.numPapers?.toString() ||
                    c.paperIds.length.toString()}
                  onPress={() => {
                    toggleDrawer();
                    setCurrentCollection(c);
                  }}
                  selected={c.key === currentCollection?.key}
                />
              ))}
        />

        <DrawerSection
          title="My Collections"
          items={[
            ...allCollections
                .filter((c) => !c.hidden)
                .filter((c) => !c.starred)
                .filter((c) => !c.publicCollectionKey)
                .sort((a, b) =>
                  (a.order - b.order) || a.name.localeCompare(b.name))
                .map((c) => (
                  <CollectionListItem
                    testID='drawer_collection_item'
                    title={c.name}
                    badgeText={c.paperIds.length.toString()}
                    key={c.key}
                    onPress={() => {
                      toggleDrawer();
                      setCurrentCollection(c);
                    }}
                    selected={c.key === currentCollection?.key}
                  />
                )),
            <CollectionListItem
              key={'new'}
              testID='drawer_collection_new'
              title='New Collection'
              icon={<IconAdd />}
              onPress={() => {
                showModal((close) =>
                  <Prompt
                    title="New Collection"
                    subTitle="Enter a name for your new collection"
                    initialValue="New Collection"
                    onSubmit={async (val) => {
                      await newCollection(val as string);
                      close();
                    }}
                    onCancel={close} />, {
                  title: '',
                  maxWidth: 400,
                });
              }} />]}
        />

        <DrawerSection
          title="Saved"
          items={allCollections
              .filter((c) => !c.hidden)
              .filter((c) => !c.starred)
              .filter((c) => !!c.publicCollectionKey)
              .sort((a, b) =>
                (a.order - b.order) || a.name.localeCompare(b.name))
              .map((c) => (
                <CollectionListItem
                  testID='drawer_collection_item_public'
                  title={c.name}
                  badgeText={
                    c.publicInfo?.numPapers?.toString() ||
                    c.paperIds.length.toString()}
                  key={c.key}
                  onPress={() => {
                    toggleDrawer();
                    setCurrentCollection(c);
                  }}
                  selected={c.key === currentCollection?.key}
                />
              ))}
        />

        <DrawerSection
          title="Shared with me"
          items={allCollections
              .filter((c) => !c.hidden)
              .filter((c) => !c.starred)
              .filter((c) => !!c.sharedCollectionKey)
              .sort((a, b) =>
                (a.order - b.order) || a.name.localeCompare(b.name))
              .map((c) => (
                <CollectionListItem
                  testID='drawer_collection_item_public'
                  title={c.name}
                  badgeText={
                    c.publicInfo?.numPapers?.toString() ||
                    c.paperIds.length.toString()}
                  key={c.key}
                  onPress={() => {
                    toggleDrawer();
                    setCurrentCollection(c);
                  }}
                  selected={c.key === currentCollection?.key}
                />
              ))}
        />

      </ScrollView>
      <Divider />
      <CollectionListItem
        key="account"
        title={isLoggedIn() ? 'Account' : 'Login'}
        onPress={() => {
          navigate('Preferences', {screen: 'Account'});
        }}
        icon={isLoggedIn() ? <IconAccount /> : <IconLogin />}
      />
      <CollectionListItem
        testID="btn_preferences"
        title="Preferences"
        onPress={() => {
          navigate('Preferences', {screen: 'Main'});
        }}
        icon={<IconConfig />}
      />
    </View>
  );
};

export default CollectionDrawer;
