import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {
  createStackNavigator, StackNavigationProp} from '@react-navigation/stack';
import {Button, Text, useTheme} from '@rneui/themed';
import React, {useContext, useEffect, useState} from 'react';
import {Pressable, View} from 'react-native';
import {useRecoilValue} from 'recoil';

import Collection from '../common/collection';
import Paper, {createNewPaper} from '../common/paper';
import ActivityIndicator from '../components/ActivityIndicator';
import {PaperList} from '../components/PaperList';
import {ScreenContainer} from '../components/ScreenContainer';
import {AppContext, NavigationContext} from '../context';
import {IconClose} from '../platform/icons';
import {allCollectionsState, isFetchingPaperState} from '../recoil/atoms';
import {isWebState, useModalState} from '../recoil/selectors';
import {PaperInfoComponent} from './PaperInfo';
import {PaperViewComponent} from './PaperView';

export type CollectionPreviewStackParamList = {
  CollectionPreview: {collection: Collection};
  PublicCollectionPreview: {collection: Collection};
  PreviewPaperInfo: {paper: Paper};
  PreviewPaperView: {paper: Paper};
};

const Stack = createStackNavigator<CollectionPreviewStackParamList>();

export const CollectionPreviewNavigator = (): JSX.Element => {
  const {closeModal} = useContext(NavigationContext);
  const isWeb = useRecoilValue(isWebState);
  const useModal = useRecoilValue(useModalState);
  const {theme} = useTheme();
  return (
    <Stack.Navigator
      initialRouteName={'CollectionPreview'}
      screenOptions={{
        headerBackTitle: 'Back',
        animationEnabled: false,
        headerStatusBarHeight: 0,
        headerRight: () => useModal && isWeb ?
        <Pressable
          testID='btn_close'
          onPress={closeModal}
          style={{marginRight: 16}}>
          <IconClose />
        </Pressable> :
        (useModal ? <Button title="Close" onPress={closeModal} /> : null),
        headerStyle: {
          borderBottomColor: theme.colors?.border,
          borderBottomWidth: 1,
          backgroundColor: theme.colors?.white,
        },
      }}
    >
      <Stack.Screen
        key="CollectionPreview"
        name="CollectionPreview"
        component={CollectionPreviewScreen}
        options={{
          headerBackTitle: 'Back',
          title: 'Public Collections',
        }}
      />
      <Stack.Screen
        key="PublicCollectionPreview"
        name="PublicCollectionPreview"
        component={PublicCollectionPreviewScreen}
      />
      <Stack.Screen
        key="PreviewPaperInfo"
        name="PreviewPaperInfo"
        component={PreviewPaperInfoScreen}
        options={{
          headerShown: true,
        }}
      />
      <Stack.Screen
        key="PreviewPaperView"
        name="PreviewPaperView"
        component={PreviewPaperViewScreen}
        options={{
          headerShown: true,
        }}
      />
    </Stack.Navigator>
  );
};

const PreviewPaperViewScreen = (): JSX.Element => {
  const route =
    useRoute<RouteProp<CollectionPreviewStackParamList, 'PreviewPaperView'>>();
  const navigation =
    useNavigation<StackNavigationProp<CollectionPreviewStackParamList>>();
  const [paper, setPaper] = useState<Paper>();

  useEffect(() => {
    setPaper(route.params.paper);
    navigation.setOptions({title: route.params.paper.title});
  }, [route.params]);

  return (paper ? <PaperViewComponent paper={paper} /> : <></>);
};

const PreviewPaperInfoScreen = () => {
  const route =
    useRoute<RouteProp<CollectionPreviewStackParamList, 'PreviewPaperView'>>();
  const navigation =
    useNavigation<StackNavigationProp<CollectionPreviewStackParamList>>();
  const [paper, setPaper] = useState<Paper>();
  const isFetching = useRecoilValue(isFetchingPaperState);

  useEffect(() => {
    setPaper(route.params.paper);
    navigation.setOptions({
      title: route.params.paper.title,
    });
  }, [route.params]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        route.params.paper && isFetching[route.params.paper.id] ?
          <View style={{paddingRight: 24}}><ActivityIndicator /></View> :
          null),
    });
  }, [isFetching]);

  return (
    paper ? <PaperInfoComponent
      paper={paper}
      setPaper={setPaper}
      onViewPaper={() => navigation.navigate('PreviewPaperView', {paper})} /> :
      <></>
  );
};

export const CollectionPreview = ({collection, ListHeaderComponent}: {
  collection: Collection;
  ListHeaderComponent?: React.ReactElement,
}) => {
  const [c, setC] = useState<Collection>();
  const navigation = useNavigation<
    StackNavigationProp<CollectionPreviewStackParamList>>();

  useEffect(() => {
    setC(collection);
  }, [collection]);

  return <View style={{flex: 1}}>
    {c ? <PaperList
      collection={c}
      setCollection={setC}
      highlightPaperId={undefined}
      onPaperSelected={(p) => {
        navigation.push('PreviewPaperInfo', {paper: {
          ...createNewPaper(),
          ...p,
        }});
      }}
      ListHeaderComponent={ListHeaderComponent} /> : <></>}
  </View>;
};

const CollectionPreviewScreen = () => {
  const [collection, setCollection] = useState<Collection>();
  const navigation = useNavigation<
    StackNavigationProp<CollectionPreviewStackParamList>>();
  const route = useRoute<RouteProp<
    CollectionPreviewStackParamList, 'CollectionPreview'>>();
  const {theme} = useTheme();

  useEffect(() => {
    setCollection(route.params.collection);
    navigation.setOptions({title: route.params.collection.name});
  }, [route.params]);

  return collection ?
    <CollectionPreview
      collection={collection}
      ListHeaderComponent={<View style={{
        padding: 16, backgroundColor: theme.colors.background}}>
        <Text style={{fontWeight: 'bold'}}>{collection.name}</Text>
        {collection.description &&
          <Text style={{paddingTop: 8}}>{collection.description}</Text>}
      </View>} /> : <></>;
};

const PublicCollectionPreviewScreen = () => {
  const {savePublicCollection} = useContext(AppContext);

  // Recoil state
  const allCollections = useRecoilValue(allCollectionsState);

  const [collection, setCollection] = useState<Collection>();
  const isSavedToLocal = () => !!collection && allCollections.some(
      (_c) => _c.publicCollectionKey === collection.key);

  const navigation = useNavigation<
    StackNavigationProp<CollectionPreviewStackParamList>>();
  const route = useRoute<RouteProp<
    CollectionPreviewStackParamList, 'PublicCollectionPreview'>>();

  useEffect(() => {
    setCollection(route.params.collection);
    navigation.setOptions({title: route.params.collection.name});
  }, [route.params]);

  useEffect(() => {
    if (!collection) return;

    const cs = Object.values(collection?.papers || {})
        .map((p) => (p as Paper).numCitations)
        .filter((c) => (c !== undefined)) as number[];
    const averageCitations = cs.length ?
        cs.reduce((a, b) => a + b) / cs.length : 0;
    if (collection?.publicInfo?.averageCitations !== averageCitations) {
      setCollection({
        ...collection,
        publicInfo: {
          ...collection?.publicInfo || {},
          averageCitations,
        },
      });
    }
  }, [collection]);

  return collection ? <CollectionPreview
    collection={collection}
    ListHeaderComponent={collection ?
      <View style={{
        padding: 16,
        flexDirection: 'row',
      }}>
        <View style={{flex: 1}}>
          {collection.description && <Text style={{
            paddingBottom: 8,
          }}>{collection.description}</Text>}
          <Text style={{flex: 1}}>
            {collection.publicInfo?.numPapers} papers
            {collection.publicInfo?.averageCitations ?
            <Text>
              , average citations (est.):
              {' '}{collection.publicInfo?.averageCitations.toFixed(0)}
            </Text> : <></>}
            {collection.publicInfo?.hIndex &&
            <Text>, h-index (est.): {collection.publicInfo?.hIndex}</Text>}
          </Text>
        </View>
        <View style={{paddingLeft: 8}}>
          <Button
            containerStyle={{}}
            title={isSavedToLocal() ? 'Saved' : 'Save'}
            disabled={isSavedToLocal()}
            color="success"
            onPress={async () => {
              await savePublicCollection(collection);
            }} />
        </View>
      </View> : <></>
    } /> : <></>;
};

const CollectionPreviewRootScreen = () => <ScreenContainer edges={['bottom']}>
  <CollectionPreviewNavigator />
</ScreenContainer>;

export default CollectionPreviewRootScreen;
