import React, {useContext, useState} from 'react';
import {View} from 'react-native';
import {useRecoilState} from 'recoil';

import Paper, {PdfHighlight} from '../common/paper';
import HighlightList from '../components/HighlightList';
import {AppContext} from '../context';
import {currentPaperState} from '../recoil/atoms';

const Notes = () => {
  const [paper, _setPaper] = useRecoilState(currentPaperState);
  const [currentHighlight, setCurrentHighlight] = useState<PdfHighlight | null>(
      null,
  );
  const {savePaperAndSync} = useContext(AppContext);

  const updateHighlights = async (hs: PdfHighlight[]) => {
    const newPaper = {...paper, pdfHighlights: hs} as Paper;
    await savePaperAndSync(newPaper);
  };

  return (
    paper && (
      <View>
        {/* <Toolbar borderBottom={true}>
      <ToolbarItem
        icon={IconNoteAdd}
        onPress={() => {
          updateHighlights([
            ...paper.pdfHighlights,
            {
              id: uuid(),
              content: {
                text: 'Page 1',
                canEdit: true,
              },
              position: {
                pageNumber: 1,
              },
              comment: {
                text: '',
                emoji: '',
              },
            } as PdfHighlight,
          ]);
        }}
      />
    </Toolbar> */}
        <HighlightList
          highlights={paper?.pdfHighlights || []}
          currentHighlight={currentHighlight}
          setCurrentHighlight={setCurrentHighlight}
          updateHighlights={updateHighlights}
        />
      </View>
    )
  );
};

export default Notes;
