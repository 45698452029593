import 'react-native-gesture-handler';

import {DarkTheme, DefaultTheme, Link} from '@react-navigation/native';
import {
  createTheme, darkColors,
  lightColors, Text, ThemeProvider, useTheme} from '@rneui/themed';
import * as SplashScreen from 'expo-splash-screen';
import _ from 'lodash';
import React, {useCallback, useEffect} from 'react';
import {Dimensions, Linking, Platform, Pressable, View} from 'react-native';
// import ReactNativeBlobUtil from 'react-native-blob-util';
import {RootSiblingParent} from 'react-native-root-siblings';
import {SafeAreaProvider} from 'react-native-safe-area-context';
// import StaticServer from 'react-native-static-server';
import {
  RecoilRoot,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState} from 'recoil';
import * as Sentry from 'sentry-expo';

import Api from './src/common/api';
import Modal from './src/components/Modal';
import ContextProvider from './src/ContextProvider';
import Navigation from './src/Navigation';
import {analytics} from './src/platform/firebase';
import {IconClose} from './src/platform/icons';
import {
  isReadyState,
  pdfReaderServerAddrState,
  windowDimensionState,
} from './src/recoil/atoms';
import {
  isModalScreenVisibleState,
  isModalVisibleState,
  modalContentState,
  modalOptionsState,
  modalScreenContentState,
  modalScreenOptionsState,
} from './src/recoil/atoms/modal';
import {settingsState} from './src/recoil/atoms/settings';
import {isElectronState, isWebState} from './src/recoil/selectors';


Sentry.init({
  dsn: 'https://e39abe5bdcb74a1ba6b3005c505fa8d4@o1005744.ingest.sentry.io/5966279',
  enableInExpoDevelopment: false,
  debug: false, // Sentry will try to print out useful debugging information
  // if something goes wrong with sending an event. Set this to `false` in
  // production.
});

if (!__DEV__) {
  console.log = () => {/**/};
}

// Keep the splash screen visible while we fetch resources
// SplashScreen.preventAutoHideAsync();

declare module '@rneui/themed' {
  export interface Colors {
    readonly background: string;
    readonly backgroundHighlight: string;
    readonly backgroundPressed: string;
    readonly backgroundHovered: string;
    readonly backgroundModal: string;
    readonly border: string;
    readonly header: string;
    readonly realWhite: string;

  }

  export interface FullTheme {
    colors: Colors;
  }
}

const DownloadTopBar = (): JSX.Element => {
  const {theme} = useTheme();
  return <View style={{
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.border,
    borderWidth: 1,
    flexDirection: 'row',
  }}
  >
    <Text style={{padding: 12, width: '100%', textAlign: 'center'}}>
      PaperShelf works better as a desktop or mobile app,
      please download it <Text onPress={() => {
        Linking.openURL('https://papershelf.app');
      }} style={{fontWeight: 'bold'}}>here</Text>.
    </Text>
    <Pressable style={{paddingRight: 12}}><IconClose /></Pressable>
  </View>;
};

/**
 * App
 */
const AppContent = (): JSX.Element => {
  // TODO: make this work for other platforms
  // const isNarrowScreen = !(Platform as PlatformIOSStatic).isPad &&
  //   Platform.OS !== 'web';
  const isMac = Dimensions.get('window').width > 1800;
  /*
  const onDimensionChanged = ({
    screen,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }) => setIsNarrowScreen(screen.width < 500 || screen.height > screen.width);

  // Adjust dimensions
  useEffect(() => {
    const screen = Dimensions.get('screen');
    setIsNarrowScreen(screen.width < 500 || screen.height > screen.width);
    Dimensions.addEventListener('change', onDimensionChanged);
    return () => Dimensions.removeEventListener('change', onDimensionChanged);
  }, []);
  */

  const setWindowDimension = useSetRecoilState(windowDimensionState);
  const setPdfReaderServerAddr = useSetRecoilState(pdfReaderServerAddrState);

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
        'change',
        ({window}) => {
          setWindowDimension(window);
        },
    );
    return () => subscription?.remove();
  }, []);

  // let server = new StaticServer(0,
  //     ReactNativeBlobUtil.fs.dirs.MainBundleDir + '/www',
  //     {localOnly: true});

  useEffect(() => {
    // server.start().then((url: string) => {
    //   setPdfReaderServerAddr(url);
    // });
    // return () => {
    //   server.isRunning().then((running: boolean) => {
    //     if (running) server.stop();
    //   });
    // };
    setPdfReaderServerAddr('https://paper-shelf-pdf-viewer.web.app');
  }, []);

  const loadSettings = async () => {
    const s = await Api.Settings.load();
    setSettings(s);
  };

  useEffect(() => {
    if (!isWeb) {
      analytics?.setAnalyticsCollectionEnabled(!__DEV__);
    }
    loadSettings();
  }, []);

  const getTheme = () => {
    return createTheme({
      lightColors: {
        white: '#fff',
        background: 'rgb(235, 235, 235)',
        header: lightColors.white,
        backgroundHighlight: 'rgb(235, 235, 235)',
        backgroundPressed: 'rgb(224, 224, 224)',
        backgroundHovered: 'rgb(245, 245, 245)',
        backgroundModal: 'rgb(255, 255, 255)',
        border: '#dadada',
        ...Platform.select({
          default: lightColors.platform.android,
          ios: lightColors.platform.ios,
        }),
        primary: '#0275d8',
        warning: '#f0ad4e',
        disabled: '#999999',
        success: '#5cb85c',
        realWhite: '#fff',
      },
      darkColors: {
        background: 'rgb(37, 37, 38)',
        backgroundHighlight: 'rgb(43, 45, 46)',
        backgroundHovered: 'rgb(61, 61, 61)',
        backgroundPressed: 'rgb(31, 31, 31)',
        backgroundModal: 'rgb(50, 50, 50)',
        border: 'rgb(50, 50, 50)',
        white: 'rgb(30, 30, 30)',
        header: 'rgb(30, 30, 30)',
        ...Platform.select({
          default: darkColors.platform.android,
          ios: darkColors.platform.ios,
        }),
        primary: '#007AFF',
        warning: '#FF9500',
        realWhite: '#fff',
      },
      components: Object.assign(
          {
            Badge: {
              badgeStyle: {
                height: 28,
                padding: 0,
                paddingHorizontal: 4,
                borderWidth: 0,
              },
              textStyle: {
                fontSize: 12,
              },
            },
          },
        isMac ? {
          Text: {
            style: {
              fontSize: 20,
            },
          },
          ListItemTitle: {
            style: {
              fontSize: 20,
            },
          },
          ListItemSubtitle: {
            style: {
              fontSize: 18,
            },
          },
        } : {},
      ),
    });
  };

  const getNavigationTheme = (darkMode: boolean) => ({
    ...(darkMode ? DarkTheme : DefaultTheme),
  });

  const [settings, setSettings] = useRecoilState(settingsState);
  const [isModalVisible, setIsModalVisible] =
    useRecoilState(isModalVisibleState);
  const modalContent = useRecoilValue(modalContentState);
  const modalOptions = useRecoilValue(modalOptionsState);
  const [isModalScreenVisible, setIsModalScreenVisible] =
    useRecoilState(isModalScreenVisibleState);
  const modalScreenContent = useRecoilValue(modalScreenContentState);
  const modalScreenOptions = useRecoilValue(modalScreenOptionsState);
  const isReady = useRecoilValue(isReadyState);
  const isElectron = useRecoilValue(isElectronState);
  const isWeb = useRecoilValue(isWebState);

  return (Object.keys(settings).length > 0 ?
      <ContextProvider
        render={(darkMode) => {
          const onLayoutRootView = useCallback(async () => {
            if (isReady) {
            // This tells the splash screen to hide immediately! If we call
            // this after `setAppIsReady`, then we may see a blank screen while
            // the app is loading its initial state and rendering its first
            // pixels. So instead, we hide the splash screen once we know the
            // root view has already performed layout.
              await SplashScreen.hideAsync();
            }
          }, [isReady]);
          return (
            <SafeAreaProvider>
              <View
                style={{flex: 1, height: 500}}
                onLayout={onLayoutRootView}
              >
                <ThemeProvider theme={getTheme()}>
                  <RootSiblingParent>
                    {isWeb && !isElectron && <DownloadTopBar />}
                    <Navigation
                      modal={<Modal
                        visible={isModalVisible}
                        setVisible={setIsModalVisible}
                        content={modalContent}
                        options={modalOptions} />}
                      modalScreen={<Modal
                        visible={isModalScreenVisible}
                        setVisible={setIsModalScreenVisible}
                        content={modalScreenContent}
                        options={modalScreenOptions} />}
                      theme={getNavigationTheme(darkMode)} />
                  </RootSiblingParent>
                </ThemeProvider>
              </View>
            </SafeAreaProvider>
          );
        }}
      /> : <></>
  );
};

const App = () => {
  return <RecoilRoot>
    <React.Suspense fallback={<></>}>
      <AppContent />
    </React.Suspense>
  </RecoilRoot>;
};

export default App;
