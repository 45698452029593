import {
  createDrawerNavigator,
} from '@react-navigation/drawer';
import {
  createNavigationContainerRef,
  NavigationContainer,
  NavigatorScreenParams,
  useNavigation} from '@react-navigation/native';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import {useTheme, useThemeMode} from '@rneui/themed';
import React, {useContext, useEffect} from 'react';
import {
  SafeAreaView, useColorScheme, View} from 'react-native';
import {TouchableHighlight} from 'react-native-gesture-handler';
import {useRecoilState, useRecoilValue} from 'recoil';

import Paper from './common/paper';
import ActivityIndicator from './components/ActivityIndicator';
import CollectionDrawer from './components/CollectionDrawer';
import PaperInfoHeader from './components/PaperInfoHeader';
import PaperListPane from './components/PaperList';
import PdfHeaderMenu from './components/PdfHeaderMenu';
import {ScreenContainer} from './components/ScreenContainer';
import {ToolbarItem} from './components/ToolbarItem';
import {AppContext, NavigationContext} from './context';
import {auth} from './platform/firebase';
import {IconConfig, IconEdit, IconOptions} from './platform/icons';
import {currentPaperState, isFetchingPaperState} from './recoil/atoms/papers';
import {settingsState} from './recoil/atoms/settings';
import {
  layoutState,
} from './recoil/selectors';
import CollectionPreviewScreen, {
  CollectionPreviewStackParamList,
} from './screens/CollectionPreview';
import HTMLPaperViewScreen from './screens/HTMLPaperView';
import NotesScreen from './screens/Notes';
import PaperInfo from './screens/PaperInfo';
import PaperViewScreen from './screens/PaperView';
import PublicCollectionsScreen from './screens/PublicCollections';
import {
  PublicCollectionsStackParamList,
} from './screens/PublicCollections';
import Settings from './screens/Settings';
import {CollectionDetails} from './screens/Settings/CollectionDetails';
import {SettingsStackParamList} from './screens/Settings/Navigator';
import ThreeColumnScreen from './screens/ThreeColumnScreen';

const Drawer = createDrawerNavigator();

export type MainStackParamList = {
  Home: undefined;
  AppIntro: undefined;
  PaperList: undefined;
  PaperView: undefined;
  HTMLPaperView: undefined;
  PaperInfo: { paper?: Paper };
  Preferences: NavigatorScreenParams<SettingsStackParamList>;
  PublicCollectionDetails: { key: string };
  PublicCollections: NavigatorScreenParams<PublicCollectionsStackParamList>;
  CollectionPreviewRoot: NavigatorScreenParams<CollectionPreviewStackParamList>;
  Notes: undefined;
};

export type InfoStackParamList = {
  PaperView: undefined;
  PaperInfo: { paper?: Paper };
  CollectionInfo: { collectionKey?: string };
  Notes: undefined;
  CollectionPreviewRoot: NavigatorScreenParams<CollectionPreviewStackParamList>;
}

const Stack = createStackNavigator<MainStackParamList>();
const InfoStack = createStackNavigator<InfoStackParamList>();
export const infoNavigationRef =
    createNavigationContainerRef<InfoStackParamList>();

export const HeaderButton = ({
  icon,
}: {
  icon: React.ReactNode;
}) => <TouchableHighlight style={{marginHorizontal: 8, borderWidth: 1}}>
  {icon}
</TouchableHighlight>;

export const Main = (): JSX.Element => {
  const currentPaper = useRecoilValue(currentPaperState);
  const isFetching = useRecoilValue(isFetchingPaperState);
  const {setMode} = useThemeMode();
  const colorScheme = useColorScheme();
  const {theme} = useTheme();
  const [settings, setSettings] = useRecoilState(settingsState);

  useEffect(() => {
    if (settings.theme === 'default') {
      setMode(colorScheme === 'dark' ? 'dark' : 'light');
    } else setMode(settings.theme === 'dark' ? 'dark' : 'light');
  }, [settings.theme, colorScheme]);

  return (
    <View style={{flex: 1}}>
      <Stack.Navigator
        initialRouteName="PaperList"
        screenOptions={{
          headerStyle: {
            backgroundColor: theme.colors?.header,
            borderBottomWidth: 1,
          },
          headerTitleStyle: {color: theme.colors?.black},
          headerBackTestID: 'btn_header_back',
          cardStyle: {flex: 1},
        }}
      >
        <Stack.Screen
          key="Home"
          name="Home"
          component={MainNavigation}
          options={{headerShown: false}}
        />
        <Stack.Screen
          key="PaperList"
          name="PaperList"
          component={PaperListScreen}
          options={{headerShown: false}}
        />
        <Stack.Screen
          key="Notes"
          name="Notes"
          component={NotesScreen}
          options={{
            headerShown: true,
            headerBackTitle: '',
            headerBackTitleVisible: false,
            headerTitle: 'Notes',
            headerTitleContainerStyle: {
              flex: 1000,
            },
            presentation: 'modal',
          }}
        />
        <Stack.Screen
          key="PaperView"
          name="PaperView"
          component={PaperViewScreen}
          options={{
            headerShown: true,
            headerBackTitle: '',
            headerBackTitleVisible: false,
            headerTitle: () => (
              <View style={{height: 32}}>
                {currentPaper && <PaperInfoHeader paper={currentPaper} />}
              </View>
            ),
            headerRight: () => <View style={{flexDirection: 'row'}}>
              <ToolbarItem
                active={settings.showPaperViewToolbar}
                icon={IconOptions} onPress={() => {
                  setSettings({
                    ...settings,
                    showPaperViewToolbar: !settings.showPaperViewToolbar});
                }} />
              <View style={{marginTop: 8}}><PdfHeaderMenu /></View>
            </View>,
            headerTitleContainerStyle: {
              flex: 1000,
            },
          }}
        />
        <Stack.Screen
          key="HTMLPaperView"
          name="HTMLPaperView"
          component={HTMLPaperViewScreen}
          options={{
            headerShown: true,
            headerBackTitle: '',
            headerBackTitleVisible: false,
            headerTitle: () => (
              <View style={{height: 32}}>
                {currentPaper && <PaperInfoHeader paper={currentPaper} />}
              </View>
            ),
            headerTitleContainerStyle: {
              flex: 1000,
            },
          }}
        />
        <Stack.Screen
          key="PaperInfo"
          name="PaperInfo"
          component={PaperInfoScreenModal}
          options={{
            headerShown: true,
            headerBackTitle: '',
            headerBackTitleVisible: false,
            headerTitle: () => (
              <View style={{height: 32}}>
                {currentPaper && <PaperInfoHeader paper={currentPaper} />}
              </View>
            ),
            headerRight: () => (
                currentPaper && isFetching[currentPaper?.id] ?
                <View style={{paddingRight: 24}}><ActivityIndicator /></View> :
                null),
            headerTitleContainerStyle: {
              flex: 1000,
            },
          }}
          initialParams={{paper: undefined}}
        />
        <Stack.Screen
          key="Preferences"
          name="Preferences"
          component={SettingsScreen}
          options={{
            headerShown: false,
            presentation: 'modal',
          }}
        />
        <Stack.Screen
          key="PublicCollections"
          name="PublicCollections"
          component={PublicCollectionsScreen}
          options={{
            headerShown: false,
            presentation: 'modal',
          }}
        />
        <Stack.Screen
          key="CollectionPreviewRoot"
          name="CollectionPreviewRoot"
          component={CollectionPreviewScreen}
          options={{
            headerShown: false,
            presentation: 'modal',
          }}
        />
      </Stack.Navigator>
    </View>
  );
};

const MainNavigation = (): JSX.Element => {
  const {theme} = useTheme();
  return (
    <Drawer.Navigator
      drawerContent={(props) =>
        <CollectionDrawer {...props} />
      }
      screenOptions={{
        headerStyle: {backgroundColor: theme.colors?.white},
        headerTitleStyle: {color: theme.colors?.black},
        sceneContainerStyle: {backgroundColor: theme.colors?.white},
      }}
      useLegacyImplementation={true}
    >
      <Drawer.Screen
        name="Main"
        component={PaperListScreen}
        options={{
          headerShown: false,
        }}
      />
    </Drawer.Navigator>
  );
};

export const PaperListScreen = () => {
  const layout = useRecoilValue(layoutState);
  const settings = useRecoilValue(settingsState);
  const {navigate} = useContext(NavigationContext);
  useEffect(() => {
    const unsubscribe = auth?.onAuthStateChanged(async (u) => {
      if (!u) {
        if (settings.shouldShowWelcome) {
          navigate('Preferences', {screen: 'Account'});
        }
      }
    });

    return unsubscribe;
  }, []);

  return layout === 'one-column' ?
      <PaperListOneColumnScreen /> : (
        layout === 'two-column' ?
            <PaperListTwoColumnScreen /> : <ThreeColumnScreen />);
};

const PaperListOneColumnScreen = () => {
  const {theme} = useTheme();
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>();
  const {changeCurrentPaper} = useContext(AppContext);

  return (
    <ScreenContainer edges={['top', 'right', 'left']}>
      <View
        style={{
          backgroundColor: theme.colors?.white,
        }}
      >
        <PaperListPane
          onPaperSelected={(p) => {
            changeCurrentPaper(p);
            navigation.navigate('PaperInfo', {paper: undefined});
          }}
          highlightCurrentPaper={false}
        />
      </View>
    </ScreenContainer>
  );
};

/**
 * Two-screen split view: Paper List and Paper Info
 * @returns Screen
 */
const PaperListTwoColumnScreen = () => {
  const {theme} = useTheme();

  const {changeCurrentPaper} = useContext(AppContext);
  const currentPaper = useRecoilValue(currentPaperState);

  return (
    <View style={{backgroundColor: theme.colors?.background, flex: 1}}>
      <View
        style={{
          flexDirection: 'row',
          height: '100%',
        }}
      >
        <SafeAreaView
          style={{
            minWidth: 300,
            maxWidth: 400,
            width: '40%',
            backgroundColor: theme.colors?.white,
            borderRightWidth: 1,
            borderRightColor: theme.colors.border,
          }}
        >
          <PaperListPane
            onLongPress={() => {
              // Do nothing
            }}
            onPaperSelected={(p) => {
              changeCurrentPaper(p);
            }}
            highlightCurrentPaper={true}
          />
        </SafeAreaView>

        <NavigationContainer independent={true} ref={infoNavigationRef}>
          <InfoStack.Navigator initialRouteName='PaperInfo'>
            <InfoStack.Screen
              key="PaperInfo"
              name="PaperInfo"
              component={PaperInfoScreen}
              options={{
                headerShown: false,
              }}
            />
            <InfoStack.Screen
              key="PaperView"
              name="PaperView"
              component={PaperViewScreen}
              options={{
                headerShown: true,
                headerBackTitle: '',
                headerBackTitleVisible: false,
                headerTitle: () => (
                  <View style={{height: 32}}>
                    {currentPaper && <PaperInfoHeader paper={currentPaper} />}
                  </View>
                ),
                headerRight: () => <PdfHeaderMenu />,
                headerTitleContainerStyle: {
                  flex: 1000,
                },
              }}
            />
            <InfoStack.Screen
              key="CollectionInfo"
              name="CollectionInfo"
              component={CollectionDetails}
              options={{
                headerBackTitle: 'Back',
                headerTitle: 'Collection Settings',
              }}
            />
            <InfoStack.Screen
              key="Notes"
              name="Notes"
              component={NotesScreen}
              options={{
                headerShown: true,
                headerBackTitle: '',
                headerBackTitleVisible: false,
                headerTitle: 'Notes',
                headerTitleContainerStyle: {
                  flex: 1000,
                },
              }}
            />
            <InfoStack.Screen
              key="CollectionPreviewRoot"
              name="CollectionPreviewRoot"
              options={{
                headerShown: false,
              }}
            >
              {() => <ScreenContainer>
                <CollectionPreviewScreen />
              </ScreenContainer>}
            </InfoStack.Screen>
          </InfoStack.Navigator>
        </NavigationContainer>
        {/* {currentPaper ? (
          <ScreenContainer>
            <View style={{
              height: 48,
              paddingVertical: 8,
              paddingHorizontal: 16,
              borderBottomColor: theme.colors.border,
              borderBottomWidth: 1}}>
              {(currentPaper && layout !== 'one-column') && showCollectionInfo ?
                  <View style={{
                    flex: 1,
                    height: 32, flexDirection: 'row', alignItems: 'center'}}
                  >
                    <Pressable style={{flexDirection: 'row'}}>
                      <IconBack />
                      <Text style={{paddingLeft: 8}}>Back</Text>
                    </Pressable>
                  </View> :
                <PaperInfoHeader paper={currentPaper} />}
            </View>
            {showCollectionInfo ? <CollectionDetails c={currentCollection} /> :
            <PaperInfo />}
          </ScreenContainer>
          ) : (
            <View></View>
            // <Home showGoBack={false} />
          )} */}
      </View>
    </View>
  );
};

// const HomeScreen = () => {
//   const {theme} = useTheme();
//   return (
//     <SafeAreaView
//       style={{height: '100%', backgroundColor: theme.colors?.background}}
//     >
//       <Home showGoBack={true} />
//     </SafeAreaView>
//   );
// };

const SettingsScreen = () => <Settings />;

const PaperInfoScreenModal = () => <ScreenContainer edges={['bottom']}>
  <PaperInfo />
</ScreenContainer>;

const PaperInfoScreen = () => <ScreenContainer>
  <PaperInfo />
</ScreenContainer>;
