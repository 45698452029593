import * as Device from 'expo-device';
import {Dimensions, ScaledSize} from 'react-native';
import {atom} from 'recoil';

export const isAdminState = atom<boolean>({
  key: 'IsAdmin',
  default: false,
});

export const deviceTypeState = atom<Device.DeviceType>({
  key: 'DeviceType',
  default: Device.DeviceType.UNKNOWN,
});

export const isReadyState = atom<boolean>({
  key: 'IsReady',
  default: false,
});

export const windowDimensionState = atom<ScaledSize>({
  key: 'WindowDimension',
  default: Dimensions.get('window'),
});

export const pdfReaderServerAddrState = atom<string>({
  key: 'PdfReaderServerAddr',
  default: 'http://localhost:3000',
});

export const showCollectionInfoState = atom<boolean>({
  key: 'ShowCollectionInfo',
  default: false,
});
