import {Button, FullTheme, Text, useTheme} from '@rneui/themed';
import {uniqBy} from 'lodash';
import {Sources} from 'paper-fetch';
import React, {useContext} from 'react';
import {
  Pressable,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import {useRecoilValue} from 'recoil';

import Paper, {PartialPaper, SearchMatch} from '../common/paper';
import {Settings} from '../common/store';
import {AppContext} from '../context';
import {
  IconAddLibrary,
  IconCitation,
  IconDownload,
} from '../platform/icons';
import {toastSuccess} from '../platform/toast';
import {allTagsState, settingsState} from '../recoil/atoms';
import {PaperTag} from './PaperTag';

export type PaperListItemProps = {
  settings?: Settings;
  addPaperToLibrary: (p: PartialPaper | Paper) => Promise<void>;
  paper: PartialPaper;
  inLibrary: boolean;
  selected?: boolean;
  onPaperSelected?: () => void;
  onLongPress?: () => void;
  theme?: FullTheme;
  containerStyle?: StyleProp<ViewStyle>;
  showChannels?: boolean;
  singleLine?: boolean;
  borderBottom?: boolean;
  loading?: boolean;
};

export const CitationNumber = ({num}: {num: number}) => {
  const getNumber = () => {
    if (num < 1000) return num;
    else if (num > 1000) return Math.floor(num / 1000) + 'k+';
  };
  return <View
    testID='citation'
    style={{
      flexDirection: 'row', alignItems: 'center',
      marginRight: 4,
    }}>
    {/* TODO: toLocaleString does not work in Android */}
    <IconCitation size={12} />
    <Text testID='citation_number'> {getNumber()}</Text>
  </View>;
};

export const TagList = ({tags}: {tags: string[]}) => {
  const allTags = useRecoilValue(allTagsState);
  return <View style={{flexDirection: 'row'}}>
    {uniqBy(
        Object.values(allTags).filter(
            (t) => !t.hidden && tags.includes(t.key),
        ),
        (tag) => JSON.stringify([tag.text, tag.icon, tag.style]),
    )
        .sort((t1, t2) => t2.priority - t1.priority)
        .map((t) => (
          <View
            key={t.key}
            style={{
              marginTop: 4,
              marginRight: 4,
            }}
          >
            <PaperTag tag={t} />
          </View>
        ))}
  </View>;
};

/**
 * PaperList's single item
 */
class _PaperListItem extends React.Component<PaperListItemProps> {
  static contextType = AppContext;

  /**
   * Check if the content should be updated based on changed props
   * @param _nextProps - next props
   * @returns true if the component needs to be updated
   */
  shouldComponentUpdate(
      nextProps: Readonly<PaperListItemProps>,
      _nextState: Readonly<Record<string, unknown>>,
      _nextContext: unknown,
  ): boolean {
    const p1 = nextProps.paper;
    const p2 = this.props.paper;
    if (!p1 || !p2) return true;
    if (p1.title !== p2.title) return true;
    if (p1.authorFull !== p2.authorFull) return true;
    if (p1.tags.length !== p2.tags.length) return true;
    if (p1.numCitations !== p2.numCitations) return true;
    if (p1.availableOffline !== p2.availableOffline) return true;
    if (nextProps.inLibrary !== this.props.inLibrary) return true;
    if (p1.matches !== p2.matches) return true;
    if (p1.pdfPath !== p2.pdfPath) return true;
    if (p1.channels !== p2.channels) return true;
    return false;
  }

  /**
   * render this component
   */
  render() {
    const {settings, theme} = this.props;
    // Enforce non-null value for type check
    if (!settings || !theme) return <></>;

    const {
      onPaperSelected,
      selected,
      paper,
      onLongPress,
      containerStyle,
      inLibrary,
      addPaperToLibrary,
    } = this.props;

    const borderBottom = this.props.borderBottom ?? true;
    const singleLine = this.props.singleLine ?? settings.paperList.singleLine;

    const getTextWithHighlights = (
        text?: string, match?: SearchMatch, trim?: boolean) => {
      if (!match || !text || !match.text) return text;
      const indices = match.indices;
      const _text = text || match.text;
      let start = 0;
      const ret: JSX.Element[] = [];
      for (const [iStart, iEnd] of indices) {
        if (iStart < start) continue;
        if (start === 0 && trim) {
          ret.push(
              <Text key={`text-${iStart}`}>
                ...{_text.slice(0, iStart).split(' ').slice(-3).join(' ')}
              </Text>);
        } else {
          ret.push(
              <Text key={`text-${iStart}`}>{_text.slice(start, iStart)}</Text>);
        }
        ret.push(
            <Text key={`text-hl-${iStart}`} style={{backgroundColor: 'yellow'}}>
              {_text.slice(iStart, iEnd + 1)}</Text>);
        start = iEnd + 1;
      }
      if (start < _text.length) ret.push(<Text>{_text.slice(start)}</Text>);
      return ret;
    };
    const title = getTextWithHighlights(paper.title, paper.matches?.title);
    const titleComponent =
      (paper.customAlias || paper.alias ?
        <Text style={{fontSize: 17}}>
          <Text style={{fontWeight: 'bold'}}>
            [{paper.customAlias ?
              getTextWithHighlights(
                  paper.customAlias, paper.matches?.customAlias) :
              getTextWithHighlights(paper.alias, paper.matches?.alias)}]
          </Text>
          <Text> - </Text>{title}
        </Text> :
        <Text
          numberOfLines={singleLine ? 1 : 3}
          style={{fontSize: 17}}
        >
          {title}
        </Text>
      );

    const authorComponent = <Text
      style={{fontStyle: 'italic', marginTop: 4}}
      numberOfLines={singleLine ? 1 : 3}
    >
      {settings.paperList.shortAuthorNames && !paper.matches?.authorFull ?
        paper.authorShort :
        getTextWithHighlights(paper.authorFull, paper.matches?.authorFull)}
    </Text>;

    const venueAndYearComponent = <View style={{
      flexDirection: 'row', alignItems: 'flex-end', marginTop: 4,
      width: '100%',
    }}>
      <View
        style={{flex: 1}}
      >
        <Text numberOfLines={settings.paperList.singleLine ? 1 : 0}>
          {[paper.venueShort || paper.venue, paper.year].join(' ').trim()}
        </Text>
        {settings.paperList.showTags && (
          <TagList tags={paper.tags} />
        )}
      </View>
      <View style={{alignSelf: 'flex-end'}}>
        {paper.numCitations !== undefined &&
                  <CitationNumber num={paper.numCitations} />}
      </View>
    </View>;

    return paper ?
    <Pressable onPress={onPaperSelected} onLongPress={onLongPress}>
      {({pressed, hovered}) => (
        <View
          style={[{
            backgroundColor: pressed ? theme.colors.backgroundPressed :
            (hovered ?
                theme.colors?.backgroundHovered :
                (selected ?
                    theme.colors?.backgroundHighlight : theme.colors?.white)),
            borderBottomWidth: borderBottom ? 1 : 0,
            borderColor: theme.colors.border,
            padding: 16,
            flexDirection: 'row',
          }, containerStyle]}
          testID="paper_list_item"
        >
          <View style={{flexDirection: 'column', flex: 1}}>
            <View style={{flexDirection: 'row'}}>
              <View style={{flex: 1}}>
                {paper.channels && paper.channels.length > 0 &&
                <Text style={{fontSize: 12}}>
                  Returned by {paper.channels?.map(
                      (c) => Sources.find((s) => s.key === c)?.name).join(', ')}
                </Text>}
                {titleComponent}
                {authorComponent}
              </View>
              {paper.availableOffline && <IconDownload />}
              {!inLibrary && <View style={{
                paddingLeft: 0}}>
                <Button
                  buttonStyle={{
                    backgroundColor: theme.colors?.white,
                  }}
                  icon={<IconAddLibrary />}
                  onPress={async () => {
                    await addPaperToLibrary(paper);
                    toastSuccess('Paper added to library');
                  }}></Button>
              </View>}
            </View>
            {paper.matches && paper.matches.abstract && <View style={{
              marginTop: 4,
            }}>
              <Text numberOfLines={3}>
                <Text style={{fontWeight: 'bold'}}>Abstract: </Text>
                {getTextWithHighlights(
                    paper.abstract, paper.matches.abstract, true)}
              </Text>
            </View>}
            {venueAndYearComponent}
          </View>
        </View>)}
    </Pressable> : <></>;
  }
}

const PaperListItem = (props: PaperListItemProps) => {
  const settings = useRecoilValue(settingsState);
  const {addPaperToLibrary} = useContext(AppContext);
  const {theme} = useTheme();
  return <_PaperListItem
    {...props}
    theme={theme as FullTheme}
    settings={settings}
    addPaperToLibrary={addPaperToLibrary} />;
};

export default PaperListItem;
