import {Dialog, Text, useTheme} from '@rneui/themed';
import React from 'react';
import {
  Pressable,
  StyleProp, TouchableOpacity, View, ViewStyle,
} from 'react-native';

import {IconClose} from '../platform/icons';

export type ModalOptionsType = {
  title?: string;
  width?: number | string;
  height?: number | string;
  maxWidth? : number | string;
  maxHeight? : number | string;
  contentContainerStyle?: StyleProp<ViewStyle>;
};

const Modal = ({
  visible,
  setVisible,
  content,
  options,
}: {
  visible: boolean;
  setVisible: (_v: boolean) => void;
  content?: JSX.Element;
  options?: ModalOptionsType;
}) => {
  const {theme} = useTheme();
  return (
    <Dialog
      testID="modal"
      isVisible={visible}
      onBackdropPress={() => setVisible(false)}
      overlayStyle={{
        width: options?.width || '90%',
        height: options?.height,
        maxWidth: options?.maxWidth || '90%',
        maxHeight: options?.maxHeight || '90%',
        padding: 0,
        borderWidth: 1,
        backgroundColor: theme.colors.backgroundModal,
        borderColor: theme.colors.border,
      }}
      supportedOrientations={['portrait', 'landscape']}
    >
      {options?.title && <View style={{
        flexDirection: 'row', height: 64,
        borderBottomColor: theme.colors.border,
        borderBottomWidth: 1,
        paddingHorizontal: 16,
        backgroundColor: theme.colors.header,
        alignItems: 'center'}}>
        <Text style={{
          fontWeight: 'bold',
          flex: 1, fontSize: 18, color: theme.colors.black}}>
          {options?.title}
        </Text>
        <Pressable
          testID="btn_close"
          onPress={() => {
            setVisible(false);
          }}
        >
          <IconClose />
        </Pressable>
      </View>}
      <View
        testID='modal_content'
        style={[{
          flex: options?.height ? 1 : undefined,
          marginTop: 0,
        }, options?.contentContainerStyle]}
      >
        <View
          style={{flex: options?.height ? 1 : undefined}}
        >{content}</View>
      </View>
    </Dialog>
  );
};

export default Modal;
